import React, { useEffect, useState } from 'react'
import jarsisURLS from '../../apiUtils/AxiosURLS';
import Loader from '../../layout/Loader';
import SideNavBar from '../../layout/SideNavBar';
import { MDBDataTable } from 'mdbreact';
import { httpPost } from '../../apiUtils/AxiosConfig';

const UserList = () => {

    const { getAllUsersFromInvitationListURL, getAllRolesFromInvitationRolesURL } = jarsisURLS;
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState({
        columns: [
            {
                label: "S. No",
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "role",
                field: "role",
                sort: "asc",
                width: 150,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 200,
            }
        ],
        rows: []
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                // ftech invitation
                const invitationResponse = await httpPost(getAllUsersFromInvitationListURL, {})
                const invitationData = invitationResponse.data.payload
                // console.log("invitationResponse.data.payload", invitationResponse.data.payload);

                // fetch invitation roles
                const invitationRoleResonponse = await httpPost(getAllRolesFromInvitationRolesURL, {})
                const invitationRoleData = invitationRoleResonponse.data.payload
                // console.log("invitationResponse.data.payload", invitationRoleResonponse.data.payload);

                // change role id to value
                const addRoleToInvitation = invitationData.map((invitation, index) => {
                    const matchedRole = invitationRoleData.find((role) => role._id === invitation.role)

                    console.log(matchedRole);


                    return {
                        sno: index + 1,
                        email: invitation.emailId,
                        role: matchedRole ? matchedRole.role : "Unknown",
                        status: invitation.active ? <span className='tw-text-blue-500'>Active</span> : <span className='tw-text-red-500'>InActive</span>

                    }
                })
                setLoading(false)
                setTableData((prevData) => ({
                    ...prevData,
                    rows: addRoleToInvitation
                }))

            }
            catch (error) {

            }
        }
        fetchData()
    }, [getAllUsersFromInvitationListURL, getAllRolesFromInvitationRolesURL])


    // useEffect(() => {

    //     const fetchData = async () => {
    //         try {
    //             setLoading(true);

    //             const res = await httpPost(getAllUsersFromInvitationListURL, {})

    //             if (res.data.success && res?.data?.payload) {
    //                 // console.log("res----------->", res);

    //                 const details = res?.data?.payload;
    //                 // console.log('details)', details);

    //                 const rows = details.map((val, index) => ({
    //                     sno: index + 1,
    //                     email: val?.emailId,
    //                     status: val?.active ? "Active" : "Inactive"
    //                     // status: getStatus(val),
    //                 }));

    //                 // console.log("rows----------->", rows);


    //                 setUserList((prevState) => ({
    //                     ...prevState,
    //                     rows,
    //                 }));

    //             } else {
    //                 setUserList([]);
    //             }
    //             setLoading(false);
    //         } catch (e) {
    //             setLoading(false);
    //             setUserList([])
    //         }

    //     }
    //     fetchData();

    // }, [])
    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>
            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">Trainee List</p>
                    </div>
                    <MDBDataTable
                        data={tableData}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        responsive
                        striped
                        small
                        className="tw-rounded-lg tw-z-0"
                    />
                </section>


            </main >
        </>
    )
}

export default UserList
import Loader from '../../layout/Loader'
import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import { httpPost } from '../../apiUtils/AxiosConfig';
import jarsisURLS from '../../apiUtils/AxiosURLS';

const Leaderboard = () => {
  const [loading, setLoading] = useState(false);
  const [inviteUsersList, setInviteUsersList] = useState([]);

  const data = {
    columns: [
      {
        label: "S. No",
        field: "sno",
        sort: "asc",
        width: 50,
      },
      {
        label: "User Info",
        field: "userInfo",
        sort: "asc",
        width: 250,
      },
      {
        label: "Accuracy",
        field: "accuracy",
        sort: "asc",
        width: 100,
      },
      {
        label: "Avg Time",
        field: "bestTime",
        sort: "asc",
        width: 100,
      },
      {
        label: "Completed Quiz",
        field: "completedQuiz",
        sort: "asc",
        width: 100,
      },
      {
        label: "Products",
        field: "products",
        sort: "asc",
        width: 100,
      }

    ],
    rows: [],
  };

  const { getUsersFromCompanyNameURL, getAllQuizURL, sentQuizInviteURL, getQuesProductDetailsURL, checkIfUserExistsURL } = jarsisURLS;
  const groupByEmail = (data) => {
    const grouped = {};

    data.forEach(async (val) => {
      const { emailId } = val;
      const traineeName = emailId;
      if (!grouped[traineeName]) {
        grouped[traineeName] = { products: [] };
      }
      grouped[traineeName].products.push(val);

    });

    return Object.values(grouped).map(val => val);
    //return grouped;
  }

  const constructTable = async (allQues) => {
    // console.log("111111Val------------>", allQues)
    const allUserResult = await Promise.all(
      allQues.map((val, index) => {
        return {
          sno: index + 1,
          userInfo: (
            <div>
              <div className="tw-font-semibold">{val?.name}</div>
              <div className="tw-text-sm tw-text-gray-500">{val[0]?.emailId}</div>
            </div>
          ),
          accuracy: isNaN(val?.AccCount) ? '0%' : `${val?.AccCount}%`,
          bestTime: val?.completionTime,
          products: Object.keys(val).length - 2,
          CompletedQuiz: Object.values(val).length,
          lastActive: "2024-03-11",
        }
      })
    )
    return allUserResult;
  }

  const processData = (data) => {
    return data.map((obj,index) => {
      const quizzes = Object.values(obj).filter((item) => typeof item === "object" && item._id);

      // Count completed quizzes
      const completedQuizzes = quizzes.filter((quiz) => quiz.isCompleted);
      const isCompletedCount = completedQuizzes.length;

      // Calculate average score only for completed quizzes
      const totalScore = completedQuizzes.reduce((acc, quiz) => acc + parseFloat(quiz.score || 0), 0);
      const avgScore = completedQuizzes.length ? ((totalScore / completedQuizzes.length).toFixed(2) + "%") : "0.00%";

      // Calculate average completion time in MM:SS format only for completed quizzes
      const totalSeconds = completedQuizzes.reduce((acc, quiz) => {
        const [minutes, seconds] = quiz.completion_time ? quiz.completion_time.split(":").map(Number) : [0, 0];
        return acc + minutes * 60 + seconds;
      }, 0);

      const avgSeconds = completedQuizzes.length ? Math.round(totalSeconds / completedQuizzes.length) : 0;
      const avgTime = `${String(Math.floor(avgSeconds / 60)).padStart(2, '0')}:${String(avgSeconds % 60).padStart(2, '0')}`;

      // Count unique quiz names
      const uniqueQuizNames = new Set(quizzes.map((quiz) => quiz.quizId.quizName)).size;

      return {
        sno: index + 1,
        userInfo: (
          <div>
            <div className="tw-font-semibold">{obj?.name}</div>
            <div className="tw-text-sm tw-text-gray-500">{quizzes[0]?.emailId}</div>
          </div>
        ),
        accuracy: <p className='tw-ml-3'>{avgScore}</p> ,
        bestTime: <p className='tw-ml-3'>{avgTime}</p> ,
        completedQuiz : <p className='tw-ml-10'>{isCompletedCount}</p>,
        products: <p className='tw-ml-5'>{uniqueQuizNames}</p> ,
      }
     
    });
  };

  const getInviteUsers = async () => {
    try {
      setLoading(true);
      const res = await httpPost(getQuesProductDetailsURL, {})
      const { payload } = res?.data;
      const { allQuestions } = payload
      // console.log("allQuestions-------------->", allQuestions)
      const test = groupByEmail(allQuestions);
      console.log("Test-------------->", test);


      const allQues = await getAllQuestions(test);
      // console.log("AllQues????????-------------->", allQues);


      const tableObj = processData(allQues)

      // console.log("processData????????-------------->", processData);

      //  constructTable(allQues)
      // const tableObj = await constructTable(allQues);
      // console.log("tableObj---------->", tableObj)
      //setDefaultIndex(tableObj[tableObj.length - 1]?.sno)
      const quizData = { ...data, ...{ rows: tableObj } }
      //console.log("quizData---------->", quizData)
      setInviteUsersList(quizData)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInviteUsers();
  }, [])

  const getUsersName = async (emailId) => {
    try {
      // console.log("1111111111111111")
      setLoading(true);
      const res = await httpPost(checkIfUserExistsURL, { emailId: emailId });
      // console.log("22222222222", res?.data)
      if (res?.data?.success) {
        const { payload } = res?.data;
        const { userDetails } = payload;
        //console.log("userDetails------------------>", userDetails);
        // setLoading(false);
        return { name: userDetails?.name, jobTitle: userDetails?.jobTitle };
      } else {
        //setLoading(false);
        return { name: "", jobTitle: "" };
      }

    } catch (e) {
      setLoading(false);
      return { name: "", jobTitle: "" };
    }
  }

  const calculateAverageTime = (times) => {
    // Convert time strings ("MM:SS") to total seconds
    const totalSeconds = times.map(time => {
      const [minutes, seconds] = time.split(":").map(Number);
      return minutes * 60 + seconds;
    });

    // Calculate average time in seconds
    const avgSeconds = totalSeconds.reduce((a, b) => a + b, 0) / totalSeconds.length;

    // Convert back to MM:SS format
    const avgMinutes = Math.floor(avgSeconds / 60);
    const avgRemSeconds = Math.floor(avgSeconds % 60);

    // console.log("avg time-------------------->", `${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}`)

    if (`${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}` === 'NaN:NaN') {
      return `00:00`
    } else {
      return `${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}`

    }
  };

  const getAllQuestions = async (allUsers) => {
    try {
      const allUserResult = await Promise.all(
        allUsers.map(async val => {
          // console.log("allUserResult val------------------>", val);
          let totalScore = 0
          let completionTime = [];
          let completedCount = 0
          val?.products.map(item => {
            //console.log("allUserResult val------------------>", item.score);
            if (item.isCompleted) {
              totalScore = totalScore + Number(item.score)
              completionTime.push(item?.completion_time);
              completedCount++;
            } else {
              totalScore = totalScore + 0;
              completionTime.push(...completionTime)
              //completionTime += completionTime;
            }
            //return AccCount;
          })
          const userDetails = await getUsersName(val?.products[0]?.emailId);
          const { name, jobTitle } = userDetails;
          //console.log("allUserResult Acccount------------------>", completionTime);

          return { ...val?.products, name, jobTitle, AccCount: Math.round(totalScore / completedCount), completionTime: calculateAverageTime(completionTime) }

        })
      )

      // console.log("allUserResult------------------>", allUserResult);
      return allUserResult;
    } catch (e) {
      console.error("Error fetching questions:", e);
      return allUsers;
    }
  };



  return (
    <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20' >

      {/* left side menu bar */}
      <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10'>
        <Loader />
      </div>

      < SideNavBar />

      {/* right side - main content */}
      < section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5" >
        <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
          <p className="tw-font-medium tw-text-lg tw-text-gray-700">Leaderboard Champions</p>
        </div>
        <MDBDataTable
          data={inviteUsersList}
          searching={true}
          pagination={true}
          noBottomColumns
          striped
          
          responsive
          small
          className="tw-rounded-lg  "
        />
      </section >

    </main >
  )
}

export default Leaderboard
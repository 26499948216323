import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import { Modal, Button } from 'react-bootstrap';
import closeImg from '../../img/new/close.svg';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import AudioConversation from './AudioConversation';
import { FaCopy } from "react-icons/fa6";
import AnalyzeReport from '../analysis/AnalyzeReport';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';

import { useForm } from 'react-hook-form'

const CandidateList = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();


    const data = {
        columns: [
            {
                label: <div className='tw-text-center'>S. No</div>,
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },
            {
                label: "Report",
                field: "report",
                sort: "asc",
                width: 100,
            },
            {
                label: <div className='tw-text-center'>Meeting URL</div>,
                field: "meetingUrl",
                sort: "asc",
                width: 20,
            },
        ], rows: []
    };

    const [candidateInterview, setCandidateInterview] = useState(data);
    const [isupdated, setisUpdated] = useState(data);
    const [showanalysis, setshowAnalysis] = useState(false);
    const [analyzeReport, setAnalyzeReport] = useState([]);
    const [rinterviewDetails, setrInterviewDetails] = useState({})
    const [loading, setLoading] = useState(false);
    const { getAllInterviewsURL, getAnalysisbyInterviewIdURL,
        createInterviewURL, getAllInterviewQuestionsURL } = jarsisURLS;

    const getStatus = (val) => {
        let element = <div className=''>
            <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
            <span className="">Email sent</span>
        </div>;
        if (val.isStarted && val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                <span className="">Completed</span>
            </div>;
        }
        if (val.isStarted && !val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                <span className="">Incomplete</span>
            </div>;
        }
        return element;
    }

    const CopyUrl = (url) => {

        // Select the text field
        //url.select();
        //url.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(url);

        // Alert the copied text
        alert("Copied");
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await httpPost(getAllInterviewsURL, {});

            if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {
                const details = res?.data?.payload?.interviewDetails;

                const rows = details.map((val, index) => ({
                    sno: <div className='tw-text-center'>{index + 1}</div>,
                    name: val?.candidateName ? val?.candidateName : "",
                    email: val?.candidateEmail ? val?.candidateEmail : "",
                    status: getStatus(val),
                    report: val.messages.length !== 0 || val.reports.length !== 0 ? <a className='tw-text-blue-400' href='javascript:void(0);' onClick={() => viewAnalyzeReport(val?._id)}>View Analysis</a> : <p className='tw-text-black'>No Chat History</p>,
                    meetingUrl: <div className='tw-justify-center tw-flex tw-cursor-pointer'><FaCopy className='tw-text-[#3737FF]' onClick={() => CopyUrl(`URL: https://meet.jarsis.ai/${val?._id}\n Password: ${val?.password}`)} /></div>
                }));

                setCandidateInterview((prevState) => ({
                    ...prevState,
                    rows,
                }));

            } else {
                setCandidateInterview([]);
            }
            setLoading(false);
            setisUpdated(!isupdated)
        } catch (e) {
            setLoading(false);
            setCandidateInterview([])
        }

    }

    useEffect(() => {

        fetchData();

    }, [])

    useEffect(() => {

    }, [candidateInterview, isupdated])


    const viewAnalyzeReport = async (interviewId) => {
        try {
            setLoading(true);
            const res = await httpPost(getAnalysisbyInterviewIdURL, { "interviewId": interviewId });

            if (res.data.success) {
                setshowAnalysis(true);
                const { payload } = res?.data;
                setAnalyzeReport(payload?.result)
                setrInterviewDetails(payload?.interviewDetails);
            } else {
                if (res.status === 350) {
                    toast.error("Sorry,No Chat history")
                } else {
                    toast.error("Sorry,Can't generate report");
                }
                setshowAnalysis(false);
                setAnalyzeReport([]);
                setrInterviewDetails({})
            }
            setLoading(false);
        } catch (e) {
            toast.error("Sorry,Can't generate report");
            setLoading(false);
            setAnalyzeReport([])
            setshowAnalysis(false);
        }
    }


    // modal
    const [show, setShow] = useState(false);
    const [interviewDetails, setInterviewDetails] = useState([]);
    const [showAudioChats, setShowAudioChats] = useState(false);
    //const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);





    const onSubmitHandler = async (e) => {
        let name = e.name
        let email = e.email
        let interview = e.interview

        // console.log("name--------->", name);
        // console.log("email--------->", email);
        // console.log("interview--------->", interview);

        // return

        if (name && email && interview) {

            try {
                setLoading(true);

                const reqObj = {
                    candidateName: name,
                    candidateEmail: email,
                    interviewQuestions: interview

                }
                const res = await httpPost(createInterviewURL, reqObj);

                if (res.data.success === true) {
                    handleClose();
                    toast.success("Interview Scheduled Successfully")
                    //await fetchData();
                    window.location.reload();
                } else {
                    toast.error("Something went wrong")
                }
                setLoading(false)

            } catch (e) {
                setLoading(false)
                toast.error("Something went wrong")
            }
        }
    }


    useEffect(() => {
        const getInterviewDetails = async () => {
            try {
                setLoading(true);
                const res = await httpPost(getAllInterviewQuestionsURL, {});
                if (res.data.success) {
                    const { data } = res;

                    const { payload } = data;
                    const { interviewQuestionDetails } = payload;
                    setInterviewDetails(interviewQuestionDetails)
                } else {
                    setInterviewDetails([]);
                }
                setLoading(false);
            } catch (e) {
                setInterviewDetails([]);
                setLoading(false);
            }
        }
        getInterviewDetails();
    }, [])





    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-50'>
                <Loader />
            </div>



            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>


                <Modal show={show} onHide={handleClose} centered >

                    <section className='tw-flex tw-justify-between tw-items-center tw-p-7 poppins tw-border-b'>
                        <div>
                            <p className='tw-font-semibold tw-text-2xl'>Schedule a Interview</p>
                            <p className='tw-text-[#6d6d6d] tw-text-sm tw-mt-1'>Al for your recruitment process</p>
                        </div>

                        <img src={closeImg} alt="" className=' tw-w-10 tw-cursor-pointer' onClick={handleClose} />
                    </section>

                    <form onSubmit={handleSubmit(onSubmitHandler)} className='tw-p-7'>
                        <p className='tw-text-[#111111] tw-text-sm tw-mt-1'>Name</p>
                        <input
                            type="text"
                            placeholder='Enter candidate name'
                            className='tw-border tw-p-4 tw-my-3 tw-w-full tw-rounded-lg'
                            {...register('name', {
                                required: '*Name is required',
                                pattern: {
                                    value: /^[a-zA-Z. ]+$/, // Only letters (a-z, A-Z) 
                                    message: "Only letters allowed",
                                },
                            })}
                        />
                        {errors.name && <p className='tw-text-red-500 tw-mt-2'>{errors.name.message}</p>}


                        <p className='tw-text-[#111111] tw-text-sm tw-mt-1'>Email</p>
                        <input
                            type="email"
                            placeholder='Enter candidate email ID'
                            className='tw-border tw-p-4 tw-my-3 tw-w-full tw-rounded-lg'
                            {...register('email', {
                                required: '*Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: 'Invalid email address',
                                },
                            })} />
                        {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}


                        <p className='tw-text-[#111111] tw-text-sm tw-mt-1'>Interview AI</p>
                        <select
                            {...register('interview', {
                                required: '*Interview AI is required'
                            })}
                            // onChange={e => onSelectHandle(e)}
                            className="tw-border tw-p-4 tw-my-3 tw-w-full tw-rounded-lg"
                        >
                            <option value="" hidden className='tw-font-medium'>--Select--</option>
                            {interviewDetails.map((val, index) => {
                                return <option key={index} value={val?._id} className='tw-font-medium'>{val?.role}</option>
                            })}

                        </select>
                        {errors.interview && <p className='tw-text-red-500 tw-mt-2'>{errors.interview.message}</p>}
                        {/* {interviewError && <p className='tw-text-red-600'>{interviewError}</p>} */}

                        <button type='submit' className=" tw-py-4 tw-bg-black tw-text-white tw-border-none tw-cursor-pointer tw-w-full tw-rounded-lg tw-my-3">
                            Schedule a Interview
                        </button>

                    </form>

                    <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-50'>
                        <Loader />
                    </div>

                </Modal>
                {showAudioChats && <AudioConversation showAudioChats={showAudioChats} setShowAudioChats={setShowAudioChats} newaudioData={rinterviewDetails?.interviewAudioSeparate} candidateName={rinterviewDetails?.candidateName} />}
                <Modal show={showanalysis} onHide={() => setshowAnalysis(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Analyze Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>

                            <AnalyzeReport analyzeReport={analyzeReport} />
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Audio:-
                            </h1>
                            {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                                {/* <audio src={rinterviewDetails?.interviewAudio} controls /> */}
                                <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                            </div>}
                            {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                Processing......
                            </p>}
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setshowAnalysis(false)}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
                    </Modal.Footer>
                </Modal>


                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">Candidate List</p>
                        <button onClick={handleShow} className='tw-text-white tw-bg-black tw-rounded-lg tw-py-2 tw-px-4'>Schedule a Interview</button>
                    </div>
                    <MDBDataTable
                        data={candidateInterview}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        striped
                        responsive
                        small
                        className="tw-rounded-lg"
                    />
                </section>

            </main >
        </>


    )
}

export default CandidateList
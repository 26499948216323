import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import TopTitleBar from '../../layout/TopTitleBar';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
    const { loginURL, getUserDetailsURL } = jarsisURLS;

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const getUserDetail = async (token) => {
            setLoading(true)
            const res = await httpPost(getUserDetailsURL, {});
            if (res?.data?.success) {
                const { payload } = res?.data;
                const { userDetails } = payload;

                localStorage.setItem("user_name", userDetails?.name)
                localStorage.setItem("user_emailId", userDetails?.emailId)
                localStorage.setItem("user_number", userDetails?.roleNumber)
                localStorage.setItem("user_company", userDetails?.companyName)
                navigate('/landing')
            }
            setLoading(false)
        }
        //console.log("token---------->", searchParams.get("token"))
        if (searchParams.get("token")) {
            try {
                localStorage.setItem("usertoken", searchParams.get("token"))
                getUserDetail(searchParams.get("token"));
            } catch (error) {
                setLoading(false)
            }
        }
    }, [])


    const onSubmitHandle = async (e) => {

        let email = e.email
        let password = e.password

        setLoading(true);

        try {
            const res = await httpPost(loginURL, { emailId: email, password });
            if (res.data.success === true) {
                localStorage.setItem("usertoken", res.data.payload.token)
                localStorage.setItem("user_name", res?.data?.payload?.userDetails?.name)
                localStorage.setItem("user_emailId", res?.data?.payload?.userDetails?.emailId)
                localStorage.setItem("user_number", res?.data?.payload?.userDetails?.roleNumber)
                localStorage.setItem("user_company", res?.data?.payload?.userDetails?.companyName)
                navigate('/landing')
            }

            setLoading(false);
        } catch (error) {
            toast.error(error.response?.data?.message)
            setLoading(false);
        }


    }


    return (
        <>

            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-50'>
                <Loader />
            </div>

            {/* navbar */}
            <TopTitleBar />


            <main className='tw-mx-auto tw-w-[80%] lg:tw-w-[40%] tw-my-12 lg:tw-my-20'>
                <div className="text-center tw-mb-10" >
                    <h3 className="text-body-highlight tw-text-3xl tw-font-semibold">Log In</h3>
                    <p className="text-body-tertiary">Get access to your account</p>
                </div >

                <form action="" onSubmit={handleSubmit(onSubmitHandle)}>
                    {/* email */}
                    <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="email">Email address</Form.Label>
                        <div className="form-icon-container">
                            <Form.Control
                                id="email"
                                type="email"
                                className="form-icon-input"
                                placeholder="name@example.com"
                                autoComplete='off'

                                {...register('email', {
                                    required: '*Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address',
                                    },
                                })}
                            />

                            {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}

                        </div>
                    </Form.Group>



                    {/* password */}
                    <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <div className="form-icon-container">
                            <div className='tw-relative'>
                                <Form.Control className="mb-2" id="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    {...register('password', {
                                        required: '*Password is required',
                                        minLength: {
                                            value: 4,
                                            message: '*Password must be at least 4 characters',
                                        },
                                        maxLength: {
                                            value: 14,
                                            message: '*Password cannot exceed more than 14 characters',
                                        },
                                    })}
                                />
                                <p onClick={() => setShowPassword(!showPassword)} className='tw-absolute tw-top-2 tw-right-5 tw-cursor-pointer'>{showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}</p>
                            </div>

                            {errors.password && <p className='tw-text-red-500 tw-mt-2'>{errors.password.message}</p>}


                        </div>
                    </Form.Group>

                    {/* forgot password */}

                    <Link to={'/forgotpassword'} className='tw-mb-5 tw-text-sm tw-text-[#6d6d6d] hover:tw-text-gray-800'>Forgot Password?</Link>

                    <Button disabled={loading} variant="primary" type='submit' className="w-100 mb-3 tw-mt-5" >
                        Log In
                    </Button>
                </form>

                <div className="text-center">
                    <Link
                        to={`/signup`}
                        className="fs-9 fw-bold"
                    >
                        Create an account
                    </Link>
                </div>
            </main>

        </>
    )
}

export default Login
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Authorization'] = `${localStorage.getItem("usertoken")}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// axios.defaults.headers.post['Accept'] = '*/*';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

const httpGet = async (url, params) => {
    try {
        const response = axios.get(url, params, { headers: { Authorization: localStorage.getItem("usertoken") } });
        return response;
    } catch (error) {
        return error;
    }
}

const httpPost = async (url, data) => {
    try {
        const response = axios.post(url, data, { headers: { Authorization: localStorage.getItem("usertoken") } });
        return response;
    } catch (error) {
        return error;
    }
}

export { httpGet, httpPost }
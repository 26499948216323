import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader'
import { useForm } from 'react-hook-form'
import TopTitleBar from '../../layout/TopTitleBar';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig'
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {

    const { updateUserPasswordURL } = jarsisURLS;

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    let location = useLocation()
    const emailId = location.state && location.state.emailId

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmitHandle = async (e) => {
        if (e.password !== e.confirmpassword) {
            toast.error('Password does not match')
            return
        }
        setLoading(true);

        try {

            const res = await httpPost(updateUserPasswordURL, { emailId, newPassword: e.password })

            if (res.data.success === true) {
                toast.success('Password updated successfully')
                navigate('/')
            }
            else {
                toast.error("something went wrong, please try again")
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false);

        }
    }

    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-50'>
                <Loader />
            </div>

            <TopTitleBar />


            <h4 className="tw-text-center tw-mt-20 tw-text-2xl">Reset password</h4>
            <form className="tw-mt-20 tw-w-[80%] md:tw-w-[50%] lg:tw-w-[30%] tw-mx-auto" onSubmit={handleSubmit(onSubmitHandle)}>
                <div className='tw-relative'>
                    <Form.Control className="mb-2" id="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Type new password"
                        {...register('password', {
                            required: '*Password is required',
                            minLength: {
                                value: 4,
                                message: '*Password must be at least 4 characters',
                            },
                            maxLength: {
                                value: 14,
                                message: '*Password cannot exceed more than 14 characters',
                            },
                        })}
                    />
                    <p onClick={() => setShowPassword(!showPassword)} className='tw-absolute tw-top-2 tw-right-5 tw-cursor-pointer'>{showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}</p>
                </div>
                {errors.password && <p className='tw-text-red-500 tw-mt-2'>{errors.password.message}</p>}
                <div className='tw-relative'>
                    <Form.Control className="tw-mt-5" id="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        {...register('confirmpassword', {
                            required: '*Password is required',
                            minLength: {
                                value: 4,
                                message: '*Password must be at least 4 characters',
                            },
                            maxLength: {
                                value: 14,
                                message: '*Password cannot exceed more than 14 characters',
                            },
                        })}

                    />

                    <p onClick={() => setShowConfirmPassword(!showConfirmPassword)} className='tw-absolute tw-top-2 tw-right-5 tw-cursor-pointer'>{showConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}</p>

                </div>

                {errors.confirmpassword && <p className='tw-text-red-500 tw-mt-2'>{errors.confirmpassword.message}</p>}


                <Button disabled={loading} variant="primary" type='submit' className="w-100 tw-mt-5">
                    Set Password
                </Button>
            </form>

        </>
    )
}

export default ResetPassword
import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import Modal from 'react-bootstrap/Modal';
import { httpPost } from '../../apiUtils/AxiosConfig';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import Loader from '../../layout/Loader';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';


const Users = () => {
  const [loading, setLoading] = useState(false);
  const [userEmailList, setUserEmailList] = useState([]);
  const [quizNameList, setQuizNameList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [inviteUsersList, setInviteUsersList] = useState([]);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [userCalled, setUserCalled] = useState(false)


  const { getUsersFromCompanyNameURL, getAllQuizURL, sentQuizInviteURL, getQuesProductDetailsURL, checkIfUserExistsURL } = jarsisURLS;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = {
    columns: [
      {
        label: "S. No",
        field: "sno",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Role",
        field: "role",
        sort: "asc",
        width: 100,
      },

    ],
    rows: [
      {
        sno: 1,
        name: "John Doe",
        email: "john@example.com",
        status: "Active",
        role: "Admin",
        actions: (
          <button className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded">
            Edit
          </button>
        ),
      },
      {
        sno: 2,
        name: "Jane Smith",
        email: "jane@example.com",
        status: "Inactive",
        role: "User",
        actions: (
          <button className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded">
            Edit
          </button>
        ),
      },
      {
        sno: 3,
        name: "Mike Johnson",
        email: "mike@example.com",
        status: "Pending",
        role: "Editor",
        actions: (
          <button className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded">
            Edit
          </button>
        ),
      },
    ],
  };

  // useEffect(() => {

  // }, [inviteUsersList])


  const handleQuizValues = (event) => {
    // console.log("event-------->", event[0].value);
    // console.log("event-------->", event);
    setSelectedUsers(event)

  }

  const sentQuizInvite = async () => {
    try {
      if (selectedProducts.length === 0) {
        toast.error("Please select Quiz Product")
      }
      if (selectedUsers.length === 0) {
        toast.error("Please select users")
      }
      if (selectedProducts.length !== 0 && selectedUsers.length !== 0) {


        try {
          const quizId = selectedProducts?.value;
          setLoading(true);
          const emailIds = selectedUsers.map(val => val?.value);
          // console.log("selectedUsers--------->", emailIds);
          // selectedUsers.map(async (val, index) => {
          //   const emailId = val?.value;
          setShow(false);
          const res = await httpPost(sentQuizInviteURL, { emailIds: emailIds, quizId: quizId });
          // console.log("selectedUsers111111--------->", inviteUsersList);
          if (res?.data?.success) {
            const { payload } = res?.data;
            const { createdInvite } = payload;
            // const quizExistData = JSON.parse(JSON.stringify(inviteUsersList))
            // quizExistData.rows = [...quizExistData.rows, ...createdInvite]

            const tableObj = await getAllQuestions(createdInvite);
            // console.log("tableObj--------->", tableObj);
            setDefaultIndex(tableObj[tableObj.length - 1]?.sno)
            const quizData = { ...data, ...{ rows: [...inviteUsersList.rows, ...tableObj] } }
            toast.success('Invitation sent')
            setInviteUsersList(quizData)
          }
          //   if (index === selectedUsers.length - 1) {
          setLoading(false);
          //   }
          // })
        } catch (e) {
          // console.log("111111111EEEEEEEEE>>>>>>>>>>>>>------------->", e)
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const getUsersName = async (emailId) => {
    try {
      // console.log("1111111111111111")
      setLoading(true);
      const res = await httpPost(checkIfUserExistsURL, { emailId: emailId });
      // console.log("22222222222", res?.data)
      if (res?.data?.success) {
        const { payload } = res?.data;
        const { userDetails } = payload;
        console.log("userDetails------------------>", userDetails);
        // setLoading(false);
        return { name: userDetails?.name, jobTitle: userDetails?.jobTitle };
      } else {
        //setLoading(false);
        return { name: "", jobTitle: "" };
      }

    } catch (e) {
      setLoading(false);
      return { name: "", jobTitle: "" };
    }
  }

  const getAllQuestions = async (allUsers) => {
    try {
      setLoading(true)
      const allUserResult = await Promise.all(
        allUsers.map(async (val, index) => {
          const currentUser = companyUsers.filter(item => {
            // console.log("currentUser item----->", item);

            return item?.emailId === val?.emailId

          });
          //  console.log("currentUser----->", currentUser);
          const userDetails = currentUser.length !== 0 ? currentUser[0] : await getUsersName(val?.emailId);
          const { name, jobTitle } = userDetails;
          //console.log("name----->", name, "jobTitle----->", jobTitle);
          const currentIndex = parseInt(defaultIndex) !== 0 ? parseInt(defaultIndex) + 1 + index : index + 1;
          // if (parseInt(defaultIndex) !== 0) {
          //   setDefaultIndex(defaultIndex++)
          // }
          return {
            sno: currentIndex,
            name: name,
            email: val?.emailId,
            status: val?.isCompleted ? "Completed" : "InComplete",
            role: jobTitle,
            // actions: (
            //   <button className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded">
            //     Edit
            //   </button>
            // ),
          }
        })
      );
      setLoading(false);
      // console.log("allUserResult------------------>", allUserResult);
      return allUserResult;
    } catch (e) {
      setLoading(false)
      console.error("Error fetching questions:", e);
      return [];
    }
  };

  const getInviteUsers = async () => {
    try {
      setLoading(true);
      const res = await httpPost(getQuesProductDetailsURL, {})
      const { payload } = res?.data;
      const { allQuestions } = payload
      const tableObj = await getAllQuestions(allQuestions);
      //console.log("tableObj---------->", tableObj[tableObj.length - 1])
      setDefaultIndex(tableObj[tableObj.length - 1]?.sno)
      const quizData = { ...data, ...{ rows: tableObj } }
      //console.log("quizData---------->", quizData)
      setInviteUsersList(quizData)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  useEffect(() => {
    const getUserCompanywise = async () => {
      try {
        setLoading(true);
        const res = await httpPost(getUsersFromCompanyNameURL, { companyName: `${localStorage.getItem("user_company")}` })
        const { payload } = res?.data;
        const { usersList } = payload;

        const storedEmail = localStorage.getItem('user_emailId');
        setCompanyUsers(usersList);
        const emailArray = usersList.filter((user) => user.emailId.trim() !== storedEmail).map((user) => ({
          label: user.emailId,
          value: user.emailId,
        }));

        setUserEmailList(emailArray);
        setUserCalled(true)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }

    }
    getUserCompanywise();
  }, [])
  useEffect(() => {
    // const getUserCompanywise = async () => {
    //   try {
    //     setLoading(true);
    //     const res = await httpPost(getUsersFromCompanyNameURL, { companyName: `${localStorage.getItem("user_company")}` })
    //     const { payload } = res?.data;
    //     const { usersList } = payload;

    //     const storedEmail = localStorage.getItem('user_emailId');

    //     const emailArray = usersList.filter((user) => user.emailId.trim() !== storedEmail).map((user) => ({
    //       label: user.emailId,
    //       value: user.emailId,
    //     }));

    //     setUserEmailList(emailArray);
    //     setLoading(false)
    //   } catch (e) {
    //     setLoading(false)
    //   }

    // }

    const getQuiz = async () => {
      try {
        setLoading(true);
        const res = await httpPost(getAllQuizURL, {})
        const { payload } = res?.data;
        const { allQuiz } = payload
        const gettingQuizNameFromApi = allQuiz.map((val) => {
          return {
            label: val.quizName,
            value: val._id
          }
        })
        // console.log("quiz-------------->", gettingQuizNameFromApi);
        setQuizNameList(gettingQuizNameFromApi)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }

    //   getUserCompanywise();
    if (userCalled) {
      getQuiz();
      getInviteUsers();
      setUserCalled(false)
    }

  }, [userCalled])

  return (

    <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

      <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
        <Loader />
      </div>


      {/* left side menu bar */}

      <SideNavBar />

      {/* right side - main content */}
      <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
        <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
          <p className="tw-font-medium tw-text-lg tw-text-gray-700">User Management</p>
          <button onClick={handleShow} className='tw-text-white tw-bg-black tw-rounded-lg tw-py-2 tw-px-4'>Invite User</button>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Invite User Quiz</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p className='tw-py-1 tw-font-medium'>Quiz Product:</p>
            <Select className='tw-w-full' name='select-user' options={quizNameList} onChange={(e) => setSelectedProducts(e)} />

            <p className='tw-py-1 tw-mt-2 tw-font-medium'>Select Users:</p>
            <Select className='tw-w-full' name='select-user' options={userEmailList} isMulti onChange={(e) => handleQuizValues(e)} />

            <Button onClick={sentQuizInvite} disabled={loading} variant="primary" className="w-100 mb-3 tw-mt-10" >
              Invite
            </Button>
          </Modal.Body>
        </Modal>

        <MDBDataTable
          data={inviteUsersList}
          searching={true}
          pagination={true}
          noBottomColumns
          striped
          responsive
          small
          className="tw-rounded-lg"
        />
      </section>

    </main >
  )
}

export default Users
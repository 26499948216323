import React from 'react'
import logo from '../img/new/Jarsis.ai.png'
import homeActive from '../img/new/Home-active.svg'
import home from '../img/new/Home.svg'
import dashboard from '../img/new/dashboard.svg'
import dashboardActive from '../img/new/dashboard-active.svg'

import intervieew from '../img/new/intervieew.svg'
import intervieewactive from '../img/new/intervieew-active.svg'

import candidate from '../img/new/candidate-list.svg'
import candidateactive from '../img/new/candidate-list-active.svg'

import settings from '../img/new/settings.svg'
import settingsactive from '../img/new/settings-active.svg'

import trainee from '../img/new/trainee-list.svg'
import traineeactive from '../img/new/trainee-list-active.svg'
import trainer from '../img/new/trainer-ai.svg'
import traineractive from '../img/new/trainer-ai-active.svg'
import team from '../img/new/team.svg'
import logout from '../img/new/logout.svg'
import { Link, useLocation } from 'react-router-dom'

import menu from '../img/new/menu.png'
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';


const SideNavBar = () => {

    const userNumber = Number(localStorage.getItem('user_number'));
    // console.log(userNumber);

    const teamList = [
        <Link to={'/team/invite'}>Invite</Link>,
        <Link to={`/team/userList`}>User List</Link>,
        <button disabled className='disabled:tw-text-gray-400' >Coming Soon...</button>,
        <button disabled className='disabled:tw-text-gray-400' >Coming Soon...</button>,
    ];
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(location.pathname.includes('team') ? true : false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };




    // responsive 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logOut = () => {
        //localStorage.removeItem('usertoken');  
        localStorage.clear();
        window.location.href = '/';
    }

    const navItems = (
        <>
            <p className='tw-text-[#9b5feb] tw-text-sm tw-pt-0 lg:tw-pt-5'>Hi! {localStorage.getItem("user_name")}</p>
            {/* interview ai */}
            {userNumber < 4 &&
                <section>
                    <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-10 tw-border-b tw-pb-2 tw-mb-5'>Interview AI</p>

                    <Link to='/landing' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit '>
                        {location.pathname === '/landing' ?
                            <>
                                <img src={homeActive} alt="" />
                                <p className='tw-text-[#3737FF]' >Home</p>
                            </>
                            :

                            <>
                                <img src={home} alt="" />
                                <p>Home</p>
                            </>}
                    </Link>


                    <Link to='/dashboard' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit '>
                        {location.pathname === '/dashboard' ?
                            <>
                                <img src={dashboardActive} alt="" />
                                <p className='tw-text-[#3737FF]' >Dashboard</p>
                            </>
                            :

                            <>
                                <img src={dashboard} alt="" />
                                <p>Dashboard</p>
                            </>}
                    </Link>

                    <Link to='/aiconfigure' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>


                        {location.pathname === '/aiconfigure' ?
                            <>
                                <img src={intervieewactive} alt="" />
                                <p className='tw-text-[#3737FF]' >Interview</p>
                            </>
                            :

                            <>
                                <img src={intervieew} alt="" />
                                <p>Interview</p>
                            </>
                        }
                    </Link>

                    <Link to='/candidatelist' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/candidatelist' ?
                            <>
                                <img src={candidateactive} alt="" />
                                <p className='tw-text-[#3737FF]' >Candidate List</p>
                            </>
                            :

                            <>
                                <img src={candidate} alt="" />
                                <p>Candidate List</p>

                            </>}
                    </Link>





                </section>
            }

            {/* training ai */}
            <section>
                <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-5 tw-border-b tw-pb-2 tw-mb-5'>Training AI</p>

                <Link to='/PitchToAiDoctor' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                    {location.pathname === '/PitchToAiDoctor' ?
                        <>
                            <img src={traineractive} alt="" />
                            <p className='tw-text-[#3737FF]' >Pitch to AI Doctor</p>
                        </>
                        :

                        <>
                            <img src={trainer} alt="" />
                            <p>Pitch to AI Doctor</p>

                        </>}

                </Link>


                <Link to='/knowledgeToDoctorAi' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                    {location.pathname === '/knowledgeToDoctorAi' ?
                        <>
                            <img src={traineractive} alt="" />
                            <p className='tw-text-[#3737FF]' >Knowledge To Doctor Ai</p>
                        </>
                        :

                        <>
                            <img src={trainer} alt="" />
                            <p>Knowledge To Doctor Ai</p>

                        </>}

                </Link>

                <Link to='/traineeList' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                    {location.pathname === '/traineeList' ?
                        <>
                            <img src={traineeactive} alt="" />
                            <p className='tw-text-[#3737FF]' >Trainee List</p>
                        </>
                        :

                        <>
                            <img src={trainee} alt="" />
                            <p>Trainee List</p>

                        </>}

                </Link>
                {userNumber < 4 &&
                    <Link to='/knowledge' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/knowledge' ?
                            <>
                                <img src={traineeactive} alt="" />
                                <p className='tw-text-[#3737FF]' >Knowledge Base</p>
                            </>
                            :

                            <>
                                <img src={trainee} alt="" />
                                <p>Knowledge Base</p>

                            </>}

                    </Link>
                }
            </section>

            {/* Quiz ai */}
            {userNumber < 4 &&
                <section>
                    <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-5 tw-border-b tw-pb-2 tw-mb-5'>Quiz AI</p>

                    <Link to='/QuizDashboard' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/QuizDashboard' ?
                            <>
                                <img src={traineractive} alt="" />
                                <p className='tw-text-[#3737FF]' >Quiz Dashboard</p>
                            </>
                            :

                            <>
                                <img src={trainer} alt="" />
                                <p>Quiz Dashboard</p>

                            </>}

                    </Link>


                    <Link to='/learningMaterials' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/learningMaterials' ?
                            <>
                                <img src={traineractive} alt="" />
                                <p className='tw-text-[#3737FF]' >Learning Materials</p>
                            </>
                            :

                            <>
                                <img src={trainer} alt="" />
                                <p>Learning Materials</p>

                            </>}

                    </Link>

                    <Link to='/Users' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/Users' ?
                            <>
                                <img src={traineractive} alt="" />
                                <p className='tw-text-[#3737FF]' >Users</p>
                            </>
                            :

                            <>
                                <img src={trainer} alt="" />
                                <p>Users</p>

                            </>}

                    </Link>


                    <Link to='/leaderboard' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/leaderboard' ?
                            <>
                                <img src={traineractive} alt="" />
                                <p className='tw-text-[#3737FF]' >Leaderboard</p>
                            </>
                            :

                            <>
                                <img src={trainer} alt="" />
                                <p>Leaderboard</p>

                            </>}

                    </Link>
                </section>
            }


            {/* Quiz Master Pro */}
            {userNumber !== 1 &&
                <section>
                    <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-5 tw-border-b tw-pb-2 tw-mb-5'>Quiz Master Pro</p>

                    <Link to='/takeQuiz' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/takeQuiz' ?
                            <>
                                <img src={traineractive} alt="" />
                                <p className='tw-text-[#3737FF]' >Take Quiz</p>
                            </>
                            :

                            <>
                                <img src={trainer} alt="" />
                                <p>Take Quiz</p>

                            </>}

                    </Link>


                    <Link to='/myProgress' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>

                        {location.pathname === '/myProgress' ?
                            <>
                                <img src={traineractive} alt="" />
                                <p className='tw-text-[#3737FF]' >My Progress</p>
                            </>
                            :

                            <>
                                <img src={trainer} alt="" />
                                <p>My Progress</p>

                            </>}

                    </Link>
                </section>
            }

            {/* Profile ai */}
            <section>
                <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-5  tw-mb-5'>Profile AI</p>

                {/* team */}
                {userNumber < 4 &&
                    <div onClick={handleClick} className='tw-flex tw-gap-x-2 tw-items-center tw-mt-3 tw-w-fit tw-cursor-pointer'>
                        <img src={team} alt="" />
                        <p>Team</p>
                    </div>
                }
                <div className={`tw-mt-1 tw-w-full tw-overflow-hidden tw-transition-all tw-duration-500 ${isOpen ? "tw-max-h-60 tw-opacity-100 tw-visible" : "tw-max-h-0 tw-opacity-0 tw-invisible"}`}>
                    {teamList.map((item, index) => (
                        <div className="tw-p-2 tw-pl-6 " key={item + index} >
                            {item}
                        </div>
                    ))}
                </div>


                {/* settings */}
                <Link to='/settings' className='tw-flex tw-gap-x-2 tw-items-center tw-mb-3 tw-mt-2 tw-cursor-pointer tw-w-fit'>

                    {location.pathname === '/settings' ?
                        <>
                            <img src={settingsactive} alt="" />
                            <p className='tw-text-[#3737FF]' >Settings</p>
                        </>
                        :

                        <>
                            <img src={settings} alt="" />
                            <p>Settings</p>

                        </>}

                </Link>

                {(localStorage.getItem('isAdmin') === true || localStorage.getItem('isAdmin') === 'true') && <Link to='/super_admin' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit'>


                    <>
                        <img src={settings} alt="" />
                        <p>Admin</p>

                    </>

                </Link>}

            </section>

            <div onClick={() => logOut()} className='tw-flex tw-gap-x-2 tw-items-center tw-mt-14 tw-mb-5 tw-cursor-pointer tw-w-fit '>
                <img src={logout} alt="" />
                <p>Logout</p>
            </div>
        </>
    )

    return (
        <main className='tw-border tw-shadow-sm  lg:tw-rounded-3xl tw-px-5 tw-pt-5 tw-w-[100%] lg:tw-w-[16%] tw-sticky tw-top-0 tw-z-0 tw-bg-white tw-mb-10'>

            {/* title and logo */}
            <section className='tw-flex tw-gap-x-2 tw-items-center tw-justify-center lg:tw-justify-normal tw-pb-3 lg:tw-pb-0' >
                <img src={logo} alt="" className='tw-w-10 lg:tw-w-8 ' />
                <Link to={'/landing'} className='tw-font-[600] tw-text-lg lg:tw-text-sm'>jarsis.ai</Link>
                <img onClick={handleShow} src={menu} alt="" className='tw-absolute tw-left-3 tw-w-10 tw-cursor-pointer lg:tw-hidden' />
            </section>

            <Offcanvas show={show} onHide={handleClose} className='tw-w-[70%] md:tw-w-[40%] '>
                <Offcanvas.Header className='tw-flex tw-justify-end tw-pr-5 tw-pt-5' closeButton>

                </Offcanvas.Header>
                <Offcanvas.Body>
                    {navItems}
                </Offcanvas.Body>
            </Offcanvas>

            <main className='tw-hidden lg:tw-inline mb-40' >
                {navItems}
            </main>

        </main>
    )
}

export default SideNavBar
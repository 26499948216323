import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import downarrow from '../../img/downarrow.png';
import rightarrow from '../../img/rightarrow.png';
import xmark from '../../img/xmark.png';
import InputForm from './inputForm';
import QuestionPopup from './QuestionPopup';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import SideNavBar from '../../layout/SideNavBar';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';


const AiInterviewConfigure = () => {

    const [levels, setLevels] = useState(["level1"])
    const [isupdated, setIsUpdated] = useState(false);
    const [interviewDetails, setInterviewDetails] = useState([])
    const [orgInterviewDetails, setOrgInterviewDetails] = useState([])

    const [questions, setQuestions] = useState([]);

    const [promptValues, setPromptValues] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedinteviewInd, setSelectedInterviewInd] = useState("")
    const [selectedpromptInd, setSelectedPromptInd] = useState("");
    const [selectedQuesInd, setSelectedQuesInd] = useState("");
    const [formData, setFormData] = useState("");

    const [showQuesForm, setShowQuesForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activateKey, setActivateKey] = useState('0');
    const { getAllInterviewQuestionsURL, updateInterviewQuestionURL, deleteInterviewQuestionsURL } = jarsisURLS;


    // Function to add a new question
    const addPrompt = (index) => {
        const existVal = interviewDetails;
        const currentObj = existVal[index]

        const currentArry = currentObj.levels;
        currentArry.push(`Prompt ${currentArry.length + 1}`)
        // existVal[index] = { ...currentObj, currentArry }
        setInterviewDetails(existVal)
        //setQuestions([...questions, ""]);
        setIsUpdated(!isupdated)
    };

    const addQuestion = (intIndex, promptIndex) => {
        const existVal = interviewDetails;
        const currentObj = existVal[intIndex]

        const currentArry = currentObj.levels;
        console.log("currentArry----------->", currentArry[promptIndex])
        if (typeof currentArry[promptIndex] === 'string' || currentArry[promptIndex] instanceof String) {
            toast.error("Please configure AI before add questions")
        } else {
            currentArry[promptIndex].subQuestions = currentArry[promptIndex].subQuestions ? currentArry[promptIndex].subQuestions : []
            currentArry[promptIndex].subQuestions.push(`Question ${currentArry[promptIndex].subQuestions.length + 1}`)
            //existVal[intIndex] = { ...currentObj, currentArry }
            console.log("existVal----------->", existVal)
            setInterviewDetails(existVal)
            //setQuestions([...questions, ""]);
            setIsUpdated(!isupdated)
        }
    };

    // Function to handle input changes
    const handleInputChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index] = value;
        setQuestions(newQuestions);
    };

    useEffect(() => {
        console.log("setactivatekey----------->", activateKey)
    }, [levels, isupdated, activateKey])

    useEffect(() => {

        const getInterviewDetails = async () => {
            try {
                setLoading(true);
                const res = await httpPost(getAllInterviewQuestionsURL, {});
                const { data } = res;

                const { payload } = data;
                const { interviewQuestionDetails } = payload;
                const interDetails = interviewQuestionDetails.sort(x => x.isTemplate ? -1 : 1);
                console.log("interDetails------------->", interDetails)
                interDetails.map((val, index) => {

                    const existvalues = interviewDetails;
                    // existvalues[val?.role] = {}

                    //  val.levels = [];
                    existvalues.push(val);
                    setInterviewDetails(existvalues);
                    setOrgInterviewDetails(JSON.parse(JSON.stringify(existvalues)));
                    setTimeout(() => {
                        if (localStorage.getItem('newai') && val?._id.trim() === localStorage.getItem('newai').trim()) {
                            if (index !== 0) {
                                setActivateKey(index.toString());
                                document.getElementsByClassName('accordion')[0].getElementsByClassName("accordion-item")[index].getElementsByTagName('button')[0].click()
                                document.getElementsByClassName('accordion')[0].getElementsByClassName("accordion-item")[index].focus();
                            }
                            localStorage.removeItem('newai')
                        }
                        setLoading(false);
                        setIsUpdated(!isupdated)
                    }, 0);


                })

            } catch (e) {
                toast.error("Something went wrong");
                setLoading(false);
            }
        }
        getInterviewDetails();

    }, [])

    const addLevels = (index) => {
        const count = interviewDetails[index].levels.length;
        console.log("count-------->", count)
        const existArray = interviewDetails;
        const existValues = existArray[index];
        existValues.levels.push("level" + (count + 1));
        console.log("count111-------->", existValues)
        existArray[index] = existValues
        setInterviewDetails(existArray);
        setIsUpdated(!isupdated)
    }

    const handleCloseForm = () => {
        setShowForm(false)
    }

    const handleShowForm = (intId, promId) => {
        setSelectedInterviewInd(intId);
        setSelectedPromptInd(promId)
        setFormData(interviewDetails[intId].levels[promId])
        setShowForm(true)
        setIsUpdated(!isupdated)
    }

    const savePromptConfiguration = (obj) => {
        console.log("obj---------->", obj);
        setShowForm(false)

        const existvalues = interviewDetails;
        const existObj = existvalues[selectedinteviewInd]
        const existlevel = existObj.levels;
        obj.subQuestions = [];
        if (existlevel[selectedpromptInd].subQuestions) {
            obj.subQuestions = existlevel[selectedpromptInd].subQuestions
        }
        existlevel[selectedpromptInd] = obj;

        existObj.levels = existlevel
        existvalues[selectedinteviewInd] = existObj;
        setInterviewDetails(existvalues);
        setSelectedInterviewInd("");
        setSelectedPromptInd("");
        setIsUpdated(!isupdated)
    }

    const handleOpenQuesPopup = (intIndex, promptIndex, quesIndex) => {
        setSelectedInterviewInd(intIndex);
        setSelectedPromptInd(promptIndex);
        setSelectedQuesInd(quesIndex);
        setFormData(interviewDetails[intIndex].levels[promptIndex].subQuestions[quesIndex])

        setShowQuesForm(!showQuesForm)
    }

    const handleCloseQuesPopup = () => {
        setShowQuesForm(!showQuesForm)
    }

    const savePromptQuestions = (data) => {
        const existvalues = interviewDetails;
        const existObj = existvalues[selectedinteviewInd];
        const existlevel = existObj.levels;
        const existPrompt = existlevel[selectedpromptInd]
        const existSubQuestions = existPrompt.subQuestions
        existSubQuestions[selectedQuesInd] = data;
        existObj.levels = existlevel
        existvalues[selectedinteviewInd] = existObj
        setInterviewDetails(existvalues);
        setSelectedInterviewInd("");
        setSelectedPromptInd("");
        setSelectedQuesInd("")
        setIsUpdated(!isupdated)
    }

    const deletePrompt = (intIndex, prompIndex) => {
        const existvalues = interviewDetails;
        const existObj = existvalues[intIndex];
        const existlevel = existObj.levels;
        existlevel.splice(prompIndex, 1);
        setInterviewDetails(existvalues);
        setIsUpdated(!isupdated)
    }
    const deleteSubQuestions = (intIndex, prompIndex, quesIndex) => {
        const existvalues = interviewDetails;
        const existObj = existvalues[intIndex];
        const existlevel = existObj.levels;
        const existPrompt = existlevel[prompIndex]
        const existSubQuestions = existPrompt.subQuestions
        existSubQuestions.splice(quesIndex, 1)
        setInterviewDetails(existvalues);
        setIsUpdated(!isupdated)
    }

    const handleCancelSave = () => {
        setInterviewDetails(orgInterviewDetails);
        setIsUpdated(!isupdated);
    }

    const saveDiagramChanges = async (indIndex) => {
        try {
            setLoading(true);
            const existvalues = interviewDetails;
            const existObj = existvalues[indIndex];
            const id = existObj?._id
            const reqObj = { interviewId: id, dataToUpdate: existObj.levels }
            // console.log("check--------------->", reqObj)
            const res = await httpPost(updateInterviewQuestionURL, reqObj);
            const { data } = res;
            if (data?.success) {
                toast.success("Changes saved successfully");
            } else {
                toast.error("Save changes failed");
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong!")
        }

    }

    const handleDeleteInterview = async (id) => {
        try {
            setLoading(true);
            const res = await httpPost(deleteInterviewQuestionsURL, { interviewId: id });
            const { data } = res;
            if (data?.success) {
                toast.success("Changes saved successfully");
                window.location.reload()
            } else {
                toast.error("Save changes failed");
            }
            setLoading(false);


        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong!")
        }
console.log("id----------->",id);

    }

    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start'>

            {/* left side menu bar */}

            <SideNavBar />
            <main className=' lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl lg:tw-px-5 lg:tw-pt-5 tw-mb-10'>
                <div className='tw-p-2'>
                    <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                        <Loader />
                    </div>
                    {/* <div className='tw-flex tw-text-blue-500 tw-mb-5 tw-font-extrabold'><a href='javascript:void(0);' onClick={() => window.history.back()} >{"<<Back"}</a></div> */}
                    <InputForm showForm={showForm} handleCloseForm={handleCloseForm} savePromptConfiguration={savePromptConfiguration} formData={formData} />
                    <QuestionPopup showForm={showQuesForm} handleCloseForm={handleCloseQuesPopup} savePromptQuestions={savePromptQuestions} formData={formData} />
                    <Accordion defaultActiveKey={'0'} >
                        {interviewDetails.map((val, valindex) => {
                            return <Accordion.Item id={'accorItem' + valindex} eventKey={valindex.toString()} key={valindex}>
                                <Accordion.Header>{val?.role}</Accordion.Header>

                                <Accordion.Body>
                                    <div className="tw-bg-gray-100  tw-p-8 tw-overflow-auto">

                                        <div className=" tw-space-y-8 tw-relative">

                                            <p className="tw-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-light">Start the Interview</p>
                                            {/* down arrow */}
                                            <img src={downarrow} alt="" className='tw-w-10 tw-ml-24' />


                                            {val.levels.length !== 0 && val.levels.map((prompt, promptIndex) => {
                                                const { title, aiprompt, aiquestion } = prompt;
                                                return <main key={promptIndex} style={{ marginBottom: "10px" }} >

                                                    <div className='tw-flex tw-items-center tw-mr-20'>

                                                        {(typeof prompt === 'string' || prompt instanceof String)
                                                            ?
                                                            <div className='tw-relative'>
                                                                <p onClick={() => handleShowForm(valindex, promptIndex)} className=" tw-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin tw-cursor-pointer tw-text-gray-600">{prompt}</p>

                                                                <img onClick={() => deletePrompt(valindex, promptIndex)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                                            </div>
                                                            :
                                                            <div className='tw-relative'>
                                                                <div onClick={() => handleShowForm(valindex, promptIndex)} class="tw-w-64 tw-min-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-cursor-pointer " >
                                                                    <h2 className='tw-font-bold'>{title}</h2>
                                                                    <p><strong>Prompt: </strong>{aiprompt}</p>
                                                                    {aiquestion && <p><strong>Question: </strong>{aiquestion}</p>}
                                                                </div>
                                                                <img onClick={() => deletePrompt(valindex, promptIndex)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                                            </div>
                                                        }



                                                        {prompt && prompt.subQuestions && prompt.subQuestions.map((question, index) => {
                                                            const { promptques } = question;
                                                            return <div key={index} className='tw-flex tw-items-center '>
                                                                <img src={rightarrow} alt="" className='  tw-mx-16' />
                                                                {(typeof question === 'string' || question instanceof String) ?

                                                                    <div className='tw-relative'>
                                                                        <p onClick={() => handleOpenQuesPopup(valindex, promptIndex, index)} className=" tw-w-64  tw-min-w-64 tw-bg-white tw-border tw-border-dotted  tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin tw-cursor-pointer">{question}</p>

                                                                        <img onClick={() => deleteSubQuestions(valindex, promptIndex, index)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                                                    </div>
                                                                    :

                                                                    <div className='tw-relative'>
                                                                        <p onClick={() => handleOpenQuesPopup(valindex, promptIndex, index)} className=" tw-w-64 tw-min-w-64 tw-text tw-h-[44%] tw-bg-white tw-border tw-border-dotted  tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin tw-cursor-pointer">{promptques}</p>

                                                                        <img onClick={() => deleteSubQuestions(valindex, promptIndex, index)} src={xmark} alt="" className='tw-absolute tw-cursor-pointer -tw-right-2 tw-h-7 -tw-top-1' />

                                                                    </div>
                                                                }
                                                            </div>
                                                        })}
                                                        <button onClick={() => addQuestion(valindex, promptIndex)} className=' tw-ml-20 tw-pr-10 tw-text-blue-500'>Add+</button>
                                                    </div>

                                                    <img src={downarrow} alt="" className='tw-w-10 tw-mt-10 tw-ml-24' />
                                                </main>
                                            })}

                                            <button onClick={() => addPrompt(valindex)} className='tw-mt-2 tw-text-blue-500 tw-ml-24'>Add+</button>


                                            {/* End the Interview */}
                                            <div className="tw-w-64 tw-bg-white tw-border tw-border-dotted tw-text-center tw-py-4 tw-rounded tw-shadow tw-font-thin">End the Interview</div>

                                            <div className='tw-flex tw-justify-end tw-gap-x-5'>

                                                <button onClick={() => saveDiagramChanges(valindex)} className='tw-border-2 tw-bg-blue-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Save</button>
                                                {/* <button onClick={() => handleCancelSave()} className='tw-border-2 tw-bg-red-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Reset</button> */}
                                                <button onClick={() => handleDeleteInterview(val._id)} className='tw-border-2 tw-bg-red-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Delete</button>
                                            </div>

                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        })}
                    </Accordion>
                </div >
            </main>
        </main>
    )
}

export default AiInterviewConfigure
import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify';
import SideNavBar from '../../layout/SideNavBar';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';
import Select from 'react-select';

const Settings = () => {

    const { getUserDetailsURL, updateUserURL, updateUserPasswordURL, getAllRolesFromInvitationRolesURL } = jarsisURLS;


    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [designation, setDesignation] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [roleOptions, setRoleOptions] = useState([]);




    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const res = await httpPost(getAllRolesFromInvitationRolesURL, {});
                // console.log(res.data.payload)
                const responseData = res.data.payload
                const roleList = responseData.map((val) => {
                    return {
                        value: val?._id,
                        label: val?.role,
                        ...val
                    }
                })
                // console.log("roleList---------->", roleList);
                setRoleOptions(roleList)
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }

        }
        if (designation && roleOptions.length === 0) {
            fetchData()
        }

    }, [designation])

    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setLoading(true)
                const res = await httpPost(getUserDetailsURL, {});
                if (res.data.success) {
                    const { userDetails } = res?.data?.payload;
                    const name = userDetails?.name;
                    const email = userDetails?.emailId;
                    const companyName = userDetails?.companyName;
                    const designation = userDetails?.jobTitle;
                    const mobileNumber = userDetails?.phoneNumber;

                    setValue("name", name);
                    setValue("email", email);
                    setValue("company", companyName);
                    setValue("job", designation);
                    setValue("phone", mobileNumber)

                    setName(name);
                    setEmail(email);
                    setCompanyName(companyName);
                    setDesignation(designation);
                    setMobileNumber(mobileNumber);
                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }
        getUserDetails();

    }, [])


    const onSubmitHandle = async (e) => {

        let O_name = e.name
        let O_emailId = e.email
        let O_companyName = e.company
        let O_jobTitle = e.job?.role
        let O_phoneNumber = e.phone
        let O_password = e.password

        let isPasswordUpdate = false;

        let reqObj = { dataToUpdate: {} };
        if (O_name !== name) {
            reqObj.dataToUpdate = { name: O_name, ...reqObj.dataToUpdate }
        }
        if (O_emailId !== email) {
            reqObj.dataToUpdate = { emailId: O_emailId, ...reqObj.dataToUpdate }
        }
        if (O_companyName !== companyName) {
            reqObj.dataToUpdate = { companyName: O_companyName, ...reqObj.dataToUpdate }
        }
        if (O_jobTitle !== designation) {
            reqObj.dataToUpdate = { jobTitle: O_jobTitle, ...reqObj.dataToUpdate }
        }
        if (O_phoneNumber !== mobileNumber) {
            reqObj.dataToUpdate = { phoneNumber: O_phoneNumber, ...reqObj.dataToUpdate }
        }
        if (e.password) {
            reqObj = {
                "emailId": O_emailId,
                "newPassword": O_password
            };
            isPasswordUpdate = true
        }



        // return;


        setLoading(true);

        try {
            let URL = updateUserURL;
            if (isPasswordUpdate) {
                URL = updateUserPasswordURL;
            }
            const res = await httpPost(URL, reqObj)

            if (res.data.success === true) {
                toast.success("Thanks for update")
            }
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error(error.response.data.message)

        }
    }


    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start'>

            {/* left side menu bar */}
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>


            <SideNavBar />
            <main className=' lg:tw-w-[80%] lg:tw-border lg:tw-shadow-sm tw-rounded-2xl tw-px-5 tw-pt-5 tw-mb-10'>


                <main className='tw-mx-auto tw-w-[80%] md:tw-w-[50%] lg:tw-w-[40%] tw-my-10'>

                    <Form onSubmit={handleSubmit(onSubmitHandle)}>

                        <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="name">Name</Form.Label>
                            <Form.Control
                                id="name"
                                type="text"
                                placeholder="Name"
                                autoComplete='off'
                                {...register('name', {
                                    required: '*Name is required'
                                })} />

                            {errors.name && <p className='tw-text-red-500 tw-mt-2'>{errors.name.message}</p>}
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                id="email"
                                type="email"
                                disabled="true"
                                placeholder="name@example.com"
                                autoComplete='off'
                                {...register('email', {
                                    required: '*Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address',
                                    },
                                })} />

                            {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}

                        </Form.Group>


                        <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="company">Company Name</Form.Label>
                            <Form.Control id="company" type="text" placeholder="Company Name" autoComplete='off' {...register('company', {
                                required: '*Company Name is required'
                            })} />

                            {errors.company && <p className='tw-text-red-500 tw-mt-2'>{errors.company.message}</p>}
                        </Form.Group>

                        <Form.Group className="mb-3 text-start" >
                            <Form.Label htmlFor="job">Designation</Form.Label>
                            <Form.Control id="job" type="text" placeholder="Designation" readOnly autoComplete='off'  {...register('job', {
                                required: '*Designation is required'
                            })} />
                            {/* 
                            <Controller
                                name="job"
                                
                                control={control}
                                rules={{ required: '*Designation is required' }}
                                render={({ field }) => <Select
                                    {...field}
                                    options={roleOptions}
                                    
                                    value={
                                        roleOptions.filter(option => {
                                            return option.label === designation
                                        })
                                    }
                                    isSearchable
                                    isClearable
                                    placeholder="Select Role..."
                                />}
                            /> */}



                            {errors.job && <p className='tw-text-red-500 tw-mt-2'>{errors.job.message}</p>}
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="phone">Mobile Number</Form.Label>
                            <Form.Control id="phone" type="number" autoComplete='off' placeholder="Mobile Number without +" {...register('phone', {
                                required: '*Mobile Number is required',
                                minLength: {
                                    value: 10,
                                    message: '*Mobile Number must be at least 10 Number',
                                },
                                maxLength: {
                                    value: 10,
                                    message: '*Mobile Number cannot exceed more than 10 Number',
                                },
                            })} />

                            {errors.phone && <p className='tw-text-red-500 tw-mt-2'>{errors.phone.message}</p>}
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="password">New Password</Form.Label>
                            <Form.Control id="password" type="password" autoComplete='off' placeholder="New password"
                                {...register('password', {
                                    minLength: {
                                        value: 4,
                                        message: '*Password must be at least 4 characters',
                                    },
                                    maxLength: {
                                        value: 14,
                                        message: '*Password cannot exceed more than 14 characters',
                                    },
                                })}
                            />

                            {errors.password && <p className='tw-text-red-500 tw-mt-2'>{errors.password.message}</p>}
                        </Form.Group>

                        <Button disabled={loading} variant="primary" type='submit' className="w-100 mb-3" >
                            Save
                        </Button>

                    </Form>
                </main>
            </main>
        </main>
    )
}

export default Settings
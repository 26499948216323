import React, { useEffect, useState } from 'react'
import SideNavBar from './SideNavBar'
import Loader from '../../layout/Loader'
import filter from '../../img/new/filter.svg'
import search from '../../img/new/search.svg'
import dr1 from '../../img/new/doctor/dr-1.png'
import dr2 from '../../img/new/doctor/dr-2.png'
import history from '../../img/new/history.svg'
import video from '../../img/new/video.svg'
import call from '../../img/new/call.svg'
import AfterCallContent from '../TrainingAI/AfterCallContent'
import BeforeCallContent from '../TrainingAI/BeforeCallContent'
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify'
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig'

const AIList = () => {

    const { getAllTrainingsURL, getAllUsersURL, getAllInterviewsByTrainingIdURL,
        createInterviewURL, updateTrainingURL, updateInterviewDetailsURL } = jarsisURLS;

    const [allTrainings, setAllTrainings] = useState([]);
    const [allOrgTrainings, setAllOrgTrainings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTraining, setSelectedTraining] = useState({});
    const [allUsers, setAllUsers] = useState([]);
    const [allUsersOrg, setAllUsersOrg] = useState([]);
    const [selectedusers, setSelectedusers] = useState([]);
    const [selectedusersOrg, setSelectedusersOrg] = useState([]); // this is state to check with existing user
    const [searchWord, setSearchWord] = useState("");
    const [companyList, setCompanyList] = useState([])
    const [sortedCompanyList, setSortedCompanyList] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [removeUsers, setRemoveUsers] = useState([])
    let userss = [];
    let groupedByCompany = {};
    useEffect(() => {
    }, [allTrainings, selectedTraining, allUsers, sortedCompanyList, loading])
    useEffect(() => {

        const getAllTrainings = async () => {
            try {
                setLoading(true);

                const res = await httpPost(getAllTrainingsURL, {})

                setLoading(false);
                if (res?.data?.success) {
                    const { payload } = res?.data;
                    const { trainingsList } = payload;
                    const trainings = trainingsList.map(val => {
                        return {
                            id: val?._id, aiImage: val?.aiImage, name: val?.name, role: val?.role,
                            division: val?.division.map(divi => divi?.title),
                            character: val?.character.map(char => char?.title),
                            language: val?.language?.title,
                            levels: val?.levels,
                            aiType: val?.aiType
                        }
                    })
                    //console.log("trainings------------->", trainings)
                    setTimeout(async () => {
                        await getUsersforTraining(trainingsList[0])
                    }, 1000);

                    setSelectedTraining(trainings[0])
                    setAllTrainings(prev => [...prev, ...trainings]);
                    setAllOrgTrainings(prev => [...prev, ...trainings])
                }
            } catch (error) {

            }
        }

        const getAllUsers = async () => {
            try {
                setLoading(true);

                const res = await httpPost(getAllUsersURL, {})

                setLoading(false);
                if (res?.data?.success) {
                    const { payload } = res?.data;
                    const { usersList } = payload;
                    const companyArray = [];
                    const trainings = usersList.map(val => {
                        companyArray.push({ label: val?.companyName, value: val?._id, })

                        return {
                            label: val?.emailId,
                            value: val?._id,
                            ...val
                        }

                    })

                    groupedByCompany = usersList.reduce((acc, item) => {
                        const { companyName } = item;
                        if (!acc[companyName]) {
                            acc[companyName] = [];
                        }
                        acc[companyName].push(item);
                        return acc;
                    }, {});
                    // console.log("groupedByCompany----------------->", groupedByCompany)
                    setSortedCompanyList(groupedByCompany)
                    const uniqueArray = companyArray.filter((obj1, i, arr) =>
                        arr.findIndex(obj2 => (obj2.label === obj1.label)) === i
                    )
                    setCompanyList(uniqueArray)
                    //   setSelectedTraining(trainings[0])
                    setAllUsersOrg(prev => [...prev, ...trainings]);
                    userss.push(trainings);
                }
            } catch (error) {

            }
        }
        getAllUsers();
        getAllTrainings();

    }, [])

    const getUsersforTraining = async (trainings) => {
        try {
            setLoading(true)

            const res = await httpPost(getAllInterviewsByTrainingIdURL, { trainingId: trainings?._id })

            if (res?.data?.success) {
                const { interviewDetails } = res?.data?.payload;
                if (interviewDetails.length !== 0) {
                    let allUserss = allUsersOrg.length !== 0 ? allUsersOrg : userss[0];
                    let companyArray = [];
                    const users = interviewDetails.map(val => {
                        let obj = allUserss.find(o => o.emailId === val?.candidateEmail);
                        //const id = arr.find(o => o.name === 'string 1');
                        if (obj) {
                            companyArray.push({ label: obj?.companyName, value: obj?._id, })
                        }
                        return {
                            //label: val?.candidateEmail,
                            label: <p>{obj?.emailId} (<span className='tw-text-[14px] tw-text-blue-500'>{obj?.jobTitle}</span>)</p>,
                            value: obj?.emailId,
                            ...val
                        }
                    })
                    const uniqueArray = users.filter((obj1, i, arr) => {
                        return arr.findIndex(obj2 => (obj2.candidateEmail === obj1.candidateEmail)) === i
                    });


                    let selectedUserss = [];
                    const uniqueArrayCompany = companyArray.filter((obj1, i, arr) => {

                        const usersList = Object.keys(groupedByCompany).length !== 0 ? groupedByCompany[obj1.label] : sortedCompanyList[obj1.label];
                        if (usersList && usersList.length !== 0) {
                            const selectedUsers = usersList.map(users => {
                                return {
                                    label: <p>{users?.emailId} (<span className='tw-text-[14px] tw-text-blue-500'>{users?.jobTitle}</span>)</p>,
                                    value: users?.emailId,
                                    ...users
                                }

                            })
                            // selectedUsers.map(val => {
                            // selectedUserss.push(val)
                            //  })
                            selectedUserss = selectedUsers;
                        }
                        return arr.findIndex(obj2 => (obj2.label === obj1.label)) === i
                    })
                    //console.log('---------------->selectedUserss',selectedUserss);

                    setAllUsers(selectedUserss)

                    setSelectedCompanies(uniqueArrayCompany)


                    setSelectedusers(uniqueArray)
                    setSelectedusersOrg(uniqueArray)
                } else {
                    setSelectedusers([])
                    setSelectedusersOrg([])
                    setSelectedCompanies([])
                    setAllUsers([])
                }

            }
            setLoading(false)
        } catch (e) {
            //  console.log("ERRRRRRRRRR----------------->", e)
            setSelectedusers([])
            setSelectedusersOrg([])
            setLoading(false)
        }
    }

    const handleSelectTrain = async (data) => {
        data._id = data?.id;
        await getUsersforTraining(data)
        setSelectedTraining(data)
        if (window.innerWidth <= 767) {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        }
    }


    const onChangeHandler = (event, values) => {
        const name = event?.name;
        // return;
        if (name === 'select-user') {
            if (event?.action === 'remove-value') {
                const removeItem = selectedusersOrg.filter(val => val?._id === event?.removedValue?._id);
                if (removeItem.length !== 0) {
                    setRemoveUsers(prev => [...prev, ...removeItem])
                }
            }
            setSelectedusers(values);

        }
        let selectedUsers = [];
        if (name === 'select-company') {
            setSelectedCompanies(values)
            values.map(val => {
                const name = val?.label;
                const usersList = sortedCompanyList[name];

                selectedUsers = usersList.map(users => {
                    console.log("selectedUsersusers-------->", users?.jobTitle)
                    return {
                        label: <p>{users?.emailId} (<span className='tw-text-[14px] tw-text-blue-500'>{users?.jobTitle}</span>)</p>,
                        value: users?._id,
                        ...users
                    }

                })
                //setAllUsers(prev => [...prev, ...selectedUsers]);
            })
            if (event?.action === 'select-option') {
                setAllUsers(prev => [...prev, ...selectedUsers]);
            } else {
                setAllUsers(prev => [...[], ...selectedUsers]);
            }

            //console.log("pre-------->",)
        }


    }

    const getCharColor = () => {
        const items = [
            "tw-bg-red-600",
            "tw-bg-green-600",
            "tw-bg-yellow-500"
        ]
        return items[Math.floor(Math.random() * items.length)];
    }

    const onSubmitHandler = async () => {
        if (selectedusers.length !== 0 && selectedCompanies.length !== 0) {
            try {

                selectedusers.map(async (val, index) => {

                    const reqObj = {
                        candidateEmail: val?.emailId,
                        candidateName: val?.name,
                        candidateRoleNumber: val?.roleNumber,
                        candidateRole: val?.jobTitle,
                        trainingDetails: selectedTraining?.id,
                        companyName: val?.companyName,
                        isTraining: true,
                        levels: selectedTraining?.levels,
                        aiType: selectedTraining?.aiType,
                    }
                    // console.log("interview reObj----------->", reqObj);
                    // return;
                    //setLoading(true);
                    if (reqObj?.candidateEmail) {
                        setLoading(true);
                        const res = await httpPost(createInterviewURL, reqObj)


                        if (res?.data?.success) {
                            if (selectedusers.length - 1 === index) {
                                toast.success("Training assigned to users");
                                //setSelectedusers([]);
                            }
                        } else {
                        }
                        setLoading(false);
                    }

                })
            } catch (e) {
                setLoading(false);
            }
        }

        if (selectedusers.length === 0 || removeUsers.length !== 0) {
            try {

                removeUsers.map(async (val, index) => {
                    //setLoading(true);
                    const reqObj = {
                        "interviewId": val?._id,
                        "dataToUpdate": {
                            "active": false
                        }
                    }
                    setLoading(true);
                    const res = await httpPost(updateInterviewDetailsURL, reqObj)
                    if (!res?.data?.success) {
                        toast.error("Please refresh the page and remove the user")
                    }
                    if (removeUsers.length - 1 === index) {
                        toast.success("Data updated successfully");
                    }
                    const filteredobj = removeUsers.filter((item) => item._id !== val?._id);
                    setRemoveUsers(filteredobj)
                    setLoading(false);
                })


            } catch (e) {
                setLoading(false);
            }
            if (removeUsers.length === 0) {
                toast.error("Please select users")
            }

        }
        if (selectedCompanies.length === 0) {
            toast.error("Please select Companies")
        }

    }

    const filterAis = (name) => {
        const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
        try {
            if (name) {
                const filterArry = existArray.filter(val => {
                    return val?.aiType === name
                });
                setSelectedTraining(filterArry[0]);
                setAllTrainings(filterArry);
            } else {
                setSelectedTraining(existArray[0]);
                setAllTrainings(existArray);
            }
        } catch (e) {
            setSelectedTraining(existArray[0]);
            setAllTrainings(existArray);
        }
    }

    const searchWOrdHandler = (e) => {
        const value = e.target.value;
        const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
        try {
            if (value) {
                const filterArry = existArray.filter(val => {
                    return val?.name.toLowerCase().includes(value.toLowerCase()) ||
                        val?.division.toString().includes(value.toLowerCase()) ||
                        val?.character.toString().includes(value.toLowerCase());
                });
                setSelectedTraining(filterArry[0]);
                setAllTrainings(filterArry);
            } else {
                setSelectedTraining(existArray[0]);
                setAllTrainings(existArray);
            }
        } catch (e) {
            setSelectedTraining(existArray[0]);
            setAllTrainings(existArray);
        }

    }

    const searchHandler = e => {
        const value = e.target.value;
        if (value) {
            setSearchWord(value)
        } else {
            const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
            setSelectedTraining(existArray[0]);
            setAllTrainings(existArray);
        }
    }

    const deleteTraining = async () => {
        try {



            const reqObj = {
                "trainingId": selectedTraining?.id,
                "dataToUpdate": {
                    "active": false
                }
            }
            setLoading(true)

            const res = await httpPost(updateTrainingURL, reqObj)


            if (res?.data?.success) {
                const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
                if (existArray.length !== 0) {
                    const selectedINdex = existArray.findIndex(x => {
                        return x.id === selectedTraining?.id
                    });
                    existArray.splice(selectedINdex, 1);
                    setAllOrgTrainings(existArray);
                    setSelectedTraining(existArray[0])
                    setAllTrainings(existArray);
                    if (existArray.length !== allTrainings.length) {
                        const dubAllTrainings = allTrainings;
                        const searchSelectedINdex = dubAllTrainings.findIndex(x => {
                            return x.id === selectedTraining?.id
                        });
                        dubAllTrainings.splice(searchSelectedINdex, 1);
                        if (allTrainings.length === 0) {
                            window.location.reload();
                        } else {
                            setAllTrainings(dubAllTrainings);
                        }

                    }
                }

                toast.success("Training deleted successfully")
            } else {
                toast.error("Sorry,Training not deleted")
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>


            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>


            {/* left side menu bar */}

            <SideNavBar />

            {/* right side content - 1 */}

            <section className='lg:tw-w-[80%] md:tw-flex tw-px-5 tw-pt-5 lg:tw-px-0 lg:tw-pt-0 tw-gap-x-5'>

                <main className="md:tw-w-[25%] tw-border tw-rounded-3xl tw-mb-5 md:tw-mb-0 ">
                    {/* 1st section */}
                    <section className='tw-flex tw-justify-between tw-items-center tw-border-b tw-p-5'>
                        <p className="tw-font-medium tw-text- tw-text-gray-700">Al Trainers</p>
                    </section>

                    {/* 2nd section */}
                    <section className='tw-px-4 tw-py-5 tw-gap-x-3 tw-border-b'>
                        {/* <div className='tw-flex tw-items-center tw-p-2 tw-cursor-pointer tw-border tw-rounded-lg tw-gap-x-2 tw-w-1/2'>
                            <img src={filter} alt="" className='tw-w-5' />
                            <p>Divisions</p>
                        </div> */}

                        <div className='tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-border tw-rounded-lg tw-gap-x-2 tw-px-2 tw-w-full'>
                            <input onChange={e => searchWOrdHandler(e)} type="text" placeholder='Search' className='tw-outline-none tw-w-full tw-py-2' />
                            <img src={search} alt="" />
                        </div>
                        <div className='tw-flex tw-justify-between tw-mt-4'>
                            <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-cursor-pointer' onClick={() => filterAis("pitch_to_ai")}>
                                <span className='tw-text-[14px] tw-text-gray-400'>Pitch to AI</span>
                            </p>
                            <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-cursor-pointer' onClick={() => filterAis("knowledge_to_ai")}>
                                <span className='tw-text-[14px] tw-text-gray-400'>Knowledge to AI</span>
                            </p>
                        </div>
                        <div className='tw-flex tw-justify-between tw-mt-4'>
                            <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-cursor-pointer tw-bg-white' onClick={() => filterAis("")}>
                                <span className='tw-text-[14px] tw-text-blue-400'>clear</span>
                            </p>
                        </div>
                    </section>

                    {/* 3rd section */}
                    <section className='tw-pb-10'>
                        {allTrainings.length !== 0 && allTrainings.map((val, index) => {
                            return <main onClick={() => handleSelectTrain(val)} key={"train" + index} className='tw-flex tw-flex-col xl:tw-flex-row xl:tw-items-center tw-px-3 xl:tw-px-5 tw-gap-x-5 tw-py-5 tw-border-b tw-cursor-pointer'>
                                <div>
                                    <img src={val?.aiImage} alt="" className='tw-w-52 md:tw-w-16 md:tw-min-w-16 tw-mx-auto' />
                                </div>

                                <div className='tw-mt-2 xl:tw-mt-0'>
                                    <p>{val?.name}</p>
                                    <p className='tw-text-[#6d6d6d] tw-text-[10px]'>{val?.division.toString()}</p>

                                    <div className='tw-flex tw-gap-x-2 tw-mt-2'>
                                        {val?.character.length !== 0 && val?.character.map((charVal, cindex) => {

                                            return cindex < 2 && <p key={"char" + cindex} className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                                <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${getCharColor()}`} ></span>
                                                <span className='tw-text-[10px]'>{charVal}</span>
                                            </p>
                                        })}
                                        {/* <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                            <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                                            <span className='tw-text-[10px]'>Rude</span>
                                        </p>

                                        <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                            <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                                            <span className='tw-text-[10px]'>Brainy</span>
                                        </p> */}
                                        {val?.character.length > 2 && <p className='tw-border tw-rounded-sm tw-px-1 tw-text-[#6d6d6d] tw-text-xs'>+2</p>}
                                    </div>
                                </div>
                            </main>
                        })}
                    </section >


                </main >

                {/* right side content - 2 */}

                {allTrainings.length !== 0 ? < main className="md:tw-w-[75%] tw-border tw-rounded-xl tw-p-5" >
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-gray-700">{selectedTraining?.levels[0].title}</p>

                        <div className='tw-flex tw-gap-x-3'>
                            <img src={history} alt="" className='tw-border tw-p-2 tw-rounded-lg tw-cursor-pointer' />
                            <button onClick={() => window.location.href = '/super_admin?id=' + selectedTraining?.id} className='tw-bg-black tw-text-white tw-flex tw-items-center tw-mx-auto tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>Edit </button>
                            <button onClick={() => deleteTraining()} className='tw-bg-black tw-text-white tw-flex tw-items-center tw-mx-auto tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>Delete </button>
                        </div>
                    </div>

                    <main className='tw-bg-[#f6f6fa] tw-p-5 tw-rounded-lg '>
                        <div className='tw-flex tw-gap-x-2 tw-mt-2 tw-bg-white tw-w-fit tw-p-2 tw-rounded-lg'>
                            {selectedTraining?.character.map((val, index) => {
                                return <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                    <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${getCharColor()}`}></span>
                                    <span className='tw-text-xs'>{val}</span>
                                </p>
                            })}


                            {/* <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                                <span className='tw-text-xs'>Brainy</span>
                            </p>

                            <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
                                <span className='tw-text-xs'>Professional</span>
                            </p> */}

                        </div>
                        <img src={selectedTraining?.aiImage} alt="" className='tw-object-cover tw-rounded-full tw-mx-auto tw-mt-32 tw-h-40 tw-w-40' />

                        <p className='tw-font-medium tw-text-3xl tw-mt-5 tw-text-center'>{selectedTraining?.name}</p>
                        <p className='tw-text-sm tw-text-[#6d6d6d] tw-text-center'>{selectedTraining?.role}</p>

                        {/* <main className='tw-flex tw-w-fit tw-mx-auto tw-gap-x-1 tw-bg-white tw-mt-20 tw-p-2 tw-rounded-full tw-mb-10'>
                            <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#74ff6c] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                                <img src={call} alt="Description of the image" className='tw-w-4' />
                                <p className='tw-font-medium'>Start Call</p>
                            </div>

                            <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#80c6ff] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                                <img src={video} alt="Description of the img" className='tw-w-4' />
                                <p className='tw-font-medium'>Start Video Call</p>
                            </div>
                        </main> */}
                        <main className='tw-flex tw-flex-col tw-w-full tw-mx-auto tw-gap-x-1 tw-bg-white tw-mt-20 tw-p-2 tw-mb-10'>
                            <label htmlFor="company-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Select Company</label>
                            <Select className='tw-w-full' name='select-company' value={selectedCompanies} options={companyList} isMulti onChange={(e, a) => onChangeHandler(a, e)} />

                        </main>
                        <main className='tw-flex tw-flex-col tw-w-full tw-mx-auto tw-gap-x-1 tw-bg-white tw-mt-5 tw-p-2 tw-mb-10'>
                            <label htmlFor="base-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Assign to Users</label>
                            <Select className='tw-w-full' value={selectedusers} name='select-user' options={allUsers} isMulti onChange={(e, a) => onChangeHandler(a, e)} />

                        </main>
                        <button onClick={() => onSubmitHandler()} type="button" className="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center ">Assign to Users</button>
                    </main>

                </main > :
                    < main className="tw-w-[75%] tw-border tw-rounded-xl tw-p-5" >No data found</main>
                }



            </section >

        </main >
    )
}

export default AIList
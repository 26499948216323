import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Modal, Button } from 'react-bootstrap';
import closeImg from '../../img/new/close.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import { FaChartBar } from "react-icons/fa";
import SideNavBar from './SideNavBar';
import { Link } from 'react-router-dom';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig'

const AllInterviews = () => {

    const { getAllInterviewsForAdminURL } = jarsisURLS;

    const data = {
        columns: [
            {
                label: <div className='tw-text-center'>S. No</div>,
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 200,
            },
            {
                label: "Id",
                field: "id",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },
            {
                label: "Visualize",
                field: "visualize",
                sort: "asc",
                width: 100,
            }
        ], rows: []
    };

    const [candidateInterview, setCandidateInterview] = useState(data);
    const [isupdated, setisUpdated] = useState(data);

    const [loading, setLoading] = useState(false);

    const getStatus = (val) => {
        let element = <div className=''>
            <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
            <span className="">Email sent</span>
        </div>;
        if (val.isStarted && val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                <span className="">Completed</span>
            </div>;
        }
        if (val.isStarted && !val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                <span className="">Incomplete</span>
            </div>;
        }
        return element;
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await httpPost(getAllInterviewsForAdminURL, {})

                if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {
                    const details = res?.data?.payload?.interviewDetails;

                    const rows = details.map((val, index) => ({
                        sno: <div className='tw-text-center'>{index + 1}</div>,
                        name: val?.candidateName ? val?.candidateName : "",
                        email: val?.candidateEmail ? val?.candidateEmail : "",
                        id: val?._id ? val?._id : "",
                        status: getStatus(val),
                        visualize: <Link to={`/super_admin/interviews/chart?id=${val?._id}`} className='tw-ml-6 tw-flex tw-cursor-pointer'><FaChartBar className='tw-text-[#3737FF] tw-text-2xl' /></Link>
                    }));

                    setCandidateInterview((prevState) => ({
                        ...prevState,
                        rows,
                    }));

                } else {
                    setCandidateInterview([]);
                }
                setLoading(false);
                setisUpdated(!isupdated)
            } catch (e) {
                setLoading(false);
                setCandidateInterview([])
            }

        }

        fetchData();

    }, [])


    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>



            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">All Interview List</p>
                    </div>

                    <MDBDataTable
                        data={candidateInterview}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        striped
                        responsive
                        small
                        className="tw-rounded-lg"
                    />
                </section>

            </main >
        </>

    )
}

export default AllInterviews
import React, { useState } from 'react'
import SideNavBar from '../../layout/SideNavBar'
import Loader from '../../layout/Loader'
import { Link } from 'react-router-dom';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';
import { useEffect } from 'react';

const Quiz = () => {

    const { getUsersFromCompanyNameURL, getAllQuizURL, sentQuizInviteURL, getQuesProductDetailsURL, checkIfUserExistsURL } = jarsisURLS;

    const [loading, setLoading] = useState(false);
    const [userCount, setUserCount] = useState(0);
    const [productCount, setProductCount] = useState(0)
    const [avgScore, setAvgScore] = useState(0);
    const [avgTime, setAvgTime] = useState(0);

    const calculateAverageTime = (times) => {
        // Convert time strings ("MM:SS") to total seconds
        const totalSeconds = times.map(time => {
            const [minutes, seconds] = time.split(":").map(Number);
            return minutes * 60 + seconds;
        });

        // Calculate average time in seconds
        const avgSeconds = totalSeconds.reduce((a, b) => a + b, 0) / totalSeconds.length;

        // Convert back to MM:SS format
        const avgMinutes = Math.floor(avgSeconds / 60);
        const avgRemSeconds = Math.floor(avgSeconds % 60);

        console.log("avg time-------------------->", `${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}`)
        if (`${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}` === 'NaN:NaN') {
            setAvgTime(0)
        } else {
            setAvgTime(`${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}`)

        }
    };


    const groupByEmailId = (data) => {
        const grouped = {};

        data.forEach(async (val) => {
            const { _id, emailId } = val;
            const traineeName = emailId;
            if (!grouped[traineeName]) {
                grouped[traineeName] = { Users: [] };
            }
            grouped[traineeName].Users.push(val);

        });

        return Object.values(grouped).map(val => val);
        //return grouped;
    }


    const getInviteUsers = async () => {
        try {
            setLoading(true);
            const res = await httpPost(getQuesProductDetailsURL, {})
            const { payload } = res?.data;
            const { allQuestions } = payload;
            const userCount = groupByEmailId(allQuestions);
            setUserCount(userCount.length)

            // get completed quizs
            let completedQuizs = allQuestions?.filter((val) => {
                return val.isCompleted === true
            })
            console.log('completedQuizs------->', completedQuizs);

            // completed Quiz Count
            let completedQuizCount = Number(completedQuizs?.length)
            let totalScore = 0
            completedQuizs.map((val) => {
                totalScore = totalScore + Number(val.score)
            })
            // console.log("totalScore------------->", completedQuizCount);

            if (totalScore === 0 || completedQuizCount === 0) {
                setAvgScore(0)
            } else {

                setAvgScore(Math.round(totalScore / completedQuizCount))
            }

            let totalTime = [] // get all times as array


            completedQuizs.map(val => {
                totalTime.push(val.completion_time)
            })
            calculateAverageTime(totalTime) // calling avg time find functions

            console.log("totalTime---------->", totalTime);

            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    const getAllQuiz = async () => {
        try {
            setLoading(true);
            const res = await httpPost(getAllQuizURL, {})
            const { payload } = res?.data;
            const { allQuiz } = payload;
            console.log("allQuiz----------->", allQuiz)
            const userCount = allQuiz;
            setProductCount(userCount.length)
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getInviteUsers();
        getAllQuiz();
    }, [])



    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-pb-5'>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10'>
                <Loader />
            </div>
            {/* left side menu bar */}

            <SideNavBar />

            {/* right side - main content */}
            <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-py-5'>

                <div className="tw-p-8 tw-bg-gradient-to-br tw-from-indigo-50 tw-to-purple-50 tw-rounded-lg">
                    <h1 className="tw-text-3xl tw-font-bold tw-text-indigo-800 tw-bg-clip-text tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600">
                        Quiz Master Dashboard
                    </h1>


                    <main className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-14 tw-mt-5 xl:tw-pr-40">

                        <Link to={'/Users'} className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-blue-50 tw-to-blue-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className="tw-text-sm tw-font-medium tw-text-blue-700">Total Users</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-blue-800">{userCount}</p>
                            <input type='range' value={userCount} className="tw-w-full tw-mt-2 tw-bg-blue-200" />
                            <p className="tw-text-xs tw-text-blue-600 tw-mt-2">Active participants</p>
                        </Link>

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-green-50 tw-to-green-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className="tw-text-sm tw-font-medium tw-text-green-700">Products</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-green-800">{productCount}</p>
                            <input type='range' value={productCount} className="tw-w-full tw-mt-2 tw-bg-green-200" />
                            <p className="tw-text-xs tw-text-green-600 tw-mt-2">Learning materials</p>
                        </div>

                        {/* <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-amber-50 tw-to-amber-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className="tw-text-sm tw-font-medium tw-text-amber-700">Active Streaks</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-amber-800">0</p>
                            <input type='range' value={45} className="tw-w-full tw-mt-2 tw-bg-amber-200" />
                            <p className="tw-text-xs tw-text-amber-600 tw-mt-2">Consistent learners</p>
                        </div> */}

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-purple-50 tw-to-purple-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className="tw-text-sm tw-font-medium tw-text-purple-700">Avg. Accuracy</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-purple-800">{avgScore}%</p>
                            <input type='range' value={avgScore} className="tw-w-full tw-mt-2 tw-bg-purple-200" />
                            <p className="tw-text-xs tw-text-purple-600 tw-mt-2">Answer precision</p>
                        </div>

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-red-50 tw-to-red-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className="tw-text-sm tw-font-medium tw-text-red-700">Avg. Completion Time</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-red-800">{avgTime}s</p>
                            <input type='range' value={avgTime} className="tw-w-full tw-mt-2 tw-bg-red-200" />
                            <p className="tw-text-xs tw-text-red-600 tw-mt-2">Speed metrics</p>
                        </div>

                        {/* <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-red-50 tw-to-red-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className="tw-text-sm tw-font-medium tw-text-red-700">Total Attempts</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-red-800">0</p>
                            <input type='range' value={75} className="tw-w-full tw-mt-2 tw-bg-red-200" />
                            <p className="tw-text-xs tw-text-red-600 tw-mt-2">Quiz completions</p>
                        </div> */}

                    </main>
                </div>
            </main>
        </main >
    )
}

export default Quiz
import React, { useState } from 'react'
import Loader from '../../layout/Loader';
import SideNavBar from '../../layout/SideNavBar';
import { Alert, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form'
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const LearningMaterials = () => {

  const [showErr, setShowErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
  const [uploadedFile, setUploadedFile] = useState("");
  const [documentUrl, setDocumentURL] = useState("");
  const [allQuiz, setAllQuiz] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [apicount, setApiCount] = useState(0);


  const { getAllQuestionsURL, getAWSS3PresignedUrlURL, saveQuizProductURL, getAllQuizURL, updateQuizProductDetailsURL } = jarsisURLS;

  const fetchAllQuestions = async (quizId) => {
    try {
      setLoading(true)
      const res = await httpPost(getAllQuestionsURL, { quizId });
      const { payload } = res?.data;
      const { allQuestions } = payload;
      // console.log("payload res----------->", res)
      setLoading(false)
      return allQuestions
    } catch (e) {
      setLoading(false)
    }
  }

  const getAllQuestions = async (allQuiz) => {
    try {
      setLoading(true)
      const allQuizResult = await Promise.all(
        allQuiz.map(async (val) => {
          return {
            allQuiz: val,
            showQues: false,
            questions: await fetchAllQuestions(val?._id),
          };
        })
      );
      setLoading(false);
      return allQuizResult;
    } catch (e) {
      console.error("Error fetching questions:", e);
      setLoading(false)
      return [];
    }
  };

  const fetchData = async (isclear = false) => {
    try {
      setLoading(true);
      const res = await httpPost(getAllQuizURL, {});
      if (res?.data?.success) {
        const { allQuiz } = res.data.payload;
        //console.log("payload----------->", allQuiz);

        const allQuizResult = await getAllQuestions(allQuiz);
        //console.log("allQuizResult----------->", allQuizResult);
        if (isclear) {
          setAllQuiz((prev) => [...[], ...allQuizResult]);
        } else {
          setAllQuiz((prev) => [...prev, ...allQuizResult]);
        }

      }
      setLoading(false);
    } catch (e) {
      console.error("Error fetching quiz data:", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
  }, [allQuiz]);

  // useEffect(() => {

  // }, [uploadedFile]);


  const onSubmitHandle = async (data) => {
    // console.log("Data---------------->", uploadedFile)
    if (!documentUrl) {
      setUploadedFile("Please upload your document")
      //  setUploadedFile(true)
    }
    if (documentUrl) {
      try {
        setLoading(true);
        toast.success("Please wait some time, We are generating questions....")
        const res = await httpPost(saveQuizProductURL, {
          "quizName": data?.productName,
          "companyName": localStorage.getItem("user_company"),
          "quizDescription": data?.productDesc,
          "quizDocumenturl": documentUrl
        })
        if (res?.data?.success) {
          toast.success("Data saved successfully")
          fetchData(true);
          reset();
        } else {
          toast.error(res?.data?.message ? res?.data?.message : "Please click generate again")
          // window.location.reload();
          // if (apicount < 2) {
          //   onSubmitHandle(data);
          //   setApiCount(apicount + 1)
          // } else {
          //   window.location.reload();
          // }
        }
        setLoading(false);
      } catch (e) {
        setShowErr(true)
        //toast.error("Somrthing went wrong!")
        window.scrollTo(0, 0)
        setLoading(false);
        fetchData(true);
        reset();
        //window.location.reload();
        // if (apicount < 2) {
        //   onSubmitHandle(data);
        //   setApiCount(apicount + 1)
        // } else {
        //   window.location.reload();
        // }
      }

    }
  }

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const uploadDocument = async (e) => {
    // console.log("EEE---------------->", e.target.files[0])
    const file = e.target.files[0];
    try {
      let fileName = file.name;
      if (file.type === "application/pdf") {
        fileName = makeid(10) + ".pdf";
      } else {
        fileName = makeid(10) + ".docx";
      }
      setLoading(true);
      const res = await httpPost(getAWSS3PresignedUrlURL, { filePath: fileName })
      if (res?.data?.success) {
        const { payload } = res?.data;
        const { fileUrl, presignedUrl } = payload;

        const response = await fetch(presignedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: file,
        });

        if (response.ok) {
          //   const res = await httpPost(saveQuizProductURL, {
          //     "quizName": "Test",
          //     "companyName": "google",
          //     "quizDescription": "test description",
          //     "quizDocumenturl": "https://jarsis-media.s3.ap-south-1.amazonaws.com/1BevHY1Gyu.pdf"
          // })
          setDocumentURL(fileUrl);
          setUploadedFile("");
          toast.success("File upload successfully")



        } else {

        }

        setLoading(false);

      }
    } catch (e) {
      setLoading(false);
      //console.log('Upload Error------>', e)
    }
  }

  // console.log("errors------>", errors)

  const showQuestion = (index) => {
    const existArray = allQuiz;
    existArray[index].showQues = !existArray[index].showQues;
    setAllQuiz(existArray)
    setIsUpdated(!isUpdated)
  }
  useEffect(() => {

  }, [isUpdated])

  const deleteProduct = async (index) => {
    try {
      setLoading(true);
      const currentData = allQuiz[index];
      const reqObj = {
        quizId: currentData?.allQuiz?._id,
        dataToUpdate: { active: false }
      }
      // console.log("currentData--------------->", currentData)
      const res = await httpPost(updateQuizProductDetailsURL, reqObj)
      if (res?.data?.success) {
        toast.success("Product deleted successfully")
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        delete allQuiz[index]
        // console.log("allQuiz--------------->", allQuiz)
        setAllQuiz(allQuiz)
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Product not deleted")
      }
    } catch (e) {
      console.log("EEEE--------------->", e)
      setLoading(false);
      toast.error("something went wrong!")
    }
  }



  return (
    <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-pb-5'>
      <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
        <Loader />
      </div>
      {/* left side menu bar */}

      <SideNavBar />

      {/* right side - main content */}
      <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-py-5'>

        <div className="tw-p-8 tw-bg-gradient-to-br tw-from-indigo-50 tw-to-purple-50 tw-rounded-lg">
          <h1 className="tw-text-xl md:tw-text-3xl tw-font-bold tw-text-indigo-800 tw-bg-clip-text tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600">
            Upload New Learning Material
          </h1>

          {showErr && <Alert variant="danger" onClose={() => setShowErr(false)} dismissible>
            <p>
              Hey - We noticed that your file content has to be tweaked so the AI can learn more about it. We are currently taking the document to train it 🏋. We will publish the quiz in next few mins. Keep an 👁️👁️on the notification 🔔, Once the quiz is ready, you just have to publish it.
            </p>
          </Alert>}

          <form className='tw-mt-10 md:tw-w-[50%]' onSubmit={handleSubmit(onSubmitHandle)}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="name">Product Name</Form.Label>
              <Form.Control
                id="name"
                type="text"
                placeholder="Enter Product Name"
                autoComplete='off'
                {...register('productName', { required: true })}
              />
            </Form.Group>
            {errors?.productName && <p className='tw-text-red-600' >Please enter Product name</p>}

            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="name">Description</Form.Label>
              <Form.Control
                id="name"
                type="text"
                placeholder="Enter Product Description"
                autoComplete='off'
                {...register('productDesc', { required: true })}

              />
            </Form.Group>
            {/* <div className="mb-3 text-start tw-flex tw-flex-row">
              <div>
                <Form.Label htmlFor="name">Description</Form.Label>
              </div>
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <textarea
                      placeholder="Description"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  )}
                  name="productDesc"
                />
              </div>
            </div> */}
            {errors?.productDesc && <p className='tw-text-red-600'>Please enter Product description</p>}
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="name">Upload Document</Form.Label>
              <Form.Control
                id="name"
                type="file"
                accept='.pdf'
                placeholder="Enter Product Description"
                autoComplete='off'
                onChange={(e) => uploadDocument(e)}
              />
            </Form.Group>
            {uploadedFile && <p className='tw-text-red-600'>{uploadedFile}</p>}

            <Button type='submit'>Generate Quiz</Button>

          </form>
        </div>

        {allQuiz.length !== 0 && allQuiz.map((val, index) => {
          const item = val?.allQuiz;
          const questions = val?.questions;
          return <main id={`main` + index} className='tw-bg-white tw-mt-5 tw-rounded-3xl'>
            <section className=" tw-overflow-hidden tw-border-0 tw-bg-gradient-to-br tw-from-indigo-50 tw-to-purple-50 tw-shadow-lg hover:tw-shadow-xl tw-transition-all tw-duration-300  tw-rounded-xl tw-cursor-pointer" >
              <div className=' tw-pl-4 tw-pr-3 tw-py-4'>
                <p className="tw-text-xl tw-text-indigo-700 ">{item?.quizName}</p>
                <p className="tw-text-indigo-600 tw-mt-4">{item?.quizDescription}</p>
              </div>
              <div className="tw-bg-indigo-200 tw-px-7 tw-py-3  tw-gap-x-5">
                <div className='tw-flex tw-flex-row'>
                  <button onClick={() => showQuestion(index)} className="tw-bg-indigo-100 tw-text-indigo-700 tw-px-3 tw-py-2 tw-rounded-xl tw-text-sm tw-border tw-border-indigo-200 tw-duration-200 hover:tw-bg-indigo-300 hover:tw-text-white ">
                    {val?.showQues ? 'Close Quiz' : 'View Quiz'}
                  </button>
                  <button onClick={() => deleteProduct(index)} className="tw-bg-red-500 tw-ml-5 tw-text-white tw-px-3 tw-py-2 tw-rounded-xl tw-text-sm tw-border tw-border-indigo-200 tw-duration-200 hover:tw-bg-red-600">
                    Delete
                  </button>
                </div>
                {val?.showQues && <div className='tw-flex tw-flex-row'>
                  <div className='tw-mt-3'>
                    {questions.length !== 0 && questions.map((value, ind) => {
                      return <p key={`ques` + ind} className="tw-mb-3 tw-text-lg tw-text-gray-500 md:tw-text-xl">{value?.question}</p>
                    })}
                    {questions.length !== 0 && <p>Please Wait some time</p>}
                  </div>
                </div>}

              </div>
            </section>
          </main>
        })}
      </main>
    </main >
  )
}

export default LearningMaterials
import React, { useEffect, useState } from 'react';
import SideNavBar from '../../layout/SideNavBar';
import Loader from '../../layout/Loader';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';

const Invite = () => {
    const [loading, setLoading] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]); //show roles in the dropdown
    const [role, setRole] = useState('') //get string value from text input 
    const [roleDropdownId, setRoleDropdownIdId] = useState('') //get role _id from dropdown
    const [roleDropdownStringValue, setRoleDropdownStringValue] = useState('') //get role string value from dropdown
    const [roleId, setRoleId] = useState('') // To send _id payload for addRoleInInvitationRoles api

    const [showDropdownErr, setShowDropdownErr] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm();
    const { sendInviteMessageToEmailURL, addUserToInvitationListURL, addRoleInInvitationRolesURL, getAllRolesFromInvitationRolesURL } = jarsisURLS;

    useEffect(() => {
        if (role || roleDropdownId) {
            setShowDropdownErr(false)
        }
    }, [role, roleDropdownId])

    useEffect(() => {
        const fetchData = async () => {
            const res = await httpPost(getAllRolesFromInvitationRolesURL, {});
            // console.log(res.data.payload)
            const responseData = res.data.payload
            const roleList = responseData.map(({ _id, role }) => ({
                value: _id,
                label: role
            }))
            // console.log("roleList---------->", roleList);
            setRoleOptions(roleList)

        }
        fetchData()
    }, [getAllRolesFromInvitationRolesURL])

    const addDropdownvalues = (e) => {
        setRoleDropdownIdId(e?.value)
        setRoleDropdownStringValue(e?.label)
    }

    const onSubmitHandle = async (e) => {
        if (!role && !roleDropdownId) {
            setShowDropdownErr(true)
        }
        else {
            let emailId = e.email;
            let designation = role ? role : roleDropdownStringValue;
            let companyName = localStorage.getItem("user_company");
            setLoading(true);

            try {
                const res = await httpPost(sendInviteMessageToEmailURL, { emailId, designation, companyName });

                if (res.data.success === true) {
                    try {
                        toast.success("Invitation Sent, Check that Email :)");
                        if (role) {
                            try {
                                const response = await httpPost(addRoleInInvitationRolesURL, { role: role });
                                console.log(response.data.payload._id)
                                const resRoleId = response.data.payload._id
                                setRoleId(resRoleId)
                            } catch (e) {
                                toast.error(e.response?.data?.message);
                                return
                            }
                        }

                        await httpPost(addUserToInvitationListURL, { emailId, role: roleDropdownId ? roleDropdownId : roleId });

                    } catch (e) {
                        toast.error(e.response?.data?.message);
                        console.log(e);

                    }
                    setRole('')
                    setRoleDropdownIdId('')
                    setRoleId('')
                    window.location.reload();
                }
            } catch (error) {
                toast.error(error.response?.data?.message || "Failed to send invitation");
            } finally {
                setLoading(false);
            }
        }

    };

    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-stretch tw-pb-5'>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>

            <SideNavBar />

            {/* Right Side - Main Content */}
            <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-pt-5'>
                <p className='tw-font-bold'>Invite Candidate</p>

                <form action="" className='tw-mx-auto xs:tw-w-[70%] md:tw-w-[50%] tw-my-20 md:tw-my-40' onSubmit={handleSubmit(onSubmitHandle)}>
                    {/* Email Input */}
                    <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="email">Email ID:</Form.Label>
                        <Form.Control
                            id="email"
                            type="email"
                            className="form-icon-input"
                            placeholder="name@example.com"
                            autoComplete='off'
                            {...register('email', {
                                required: '*Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: 'Invalid email address',
                                },
                            })}
                        />
                        {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}
                    </Form.Group>

                    <Form.Label htmlFor="email">Designation:</Form.Label>
                    <div className='tw-border tw-border-dotted tw-p-5 tw-rounded-xl'>
                        <div className="tw-mb-2">

                            <Select
                                options={roleOptions}
                                onChange={addDropdownvalues}
                                isSearchable
                                isClearable
                                isDisabled={role}
                                placeholder="Select Role..."
                            />
                            {/* <Select value={divisions} name='select-division' options={divisionOptions} isMulti onChange={(e) => onChangeHandler('select-division', e)} /> */}
                        </div>
                        {/*<div className='tw-flex tw-justify-center tw-my-5'> OR</div>*/}
                        {/* <div className="tw-mb-5">

                            {/* <input type="text" className="tw-bg-gray-50 tw-border tw-outline-none tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5" /> */}
                        {/*<Form.Control
                                id="text"
                                type="text"
                                className="form-icon-input tw-mt-2"
                                placeholder="Type Role..."
                                autoComplete='off'
                                value={role}
                                onChange={e => setRole(e.target.value)}
                                disabled={roleDropdownId}

                            />
                        </div>*/}
                        {showDropdownErr && <p className='tw-text-red-500 tw-mt-2'>*Role is required</p>}



                    </div>

                    <Button disabled={loading} variant="primary" type='submit' className="w-100 mb-3 tw-mt-5">
                        Invite
                    </Button>
                </form>
            </main>
        </main>
    );
};

export default Invite;

import React, { useState } from 'react'
import SideNavBar from '../../layout/SideNavBar'
import Loader from '../../layout/Loader'

const QuizList = () => {
    const [loading, setLoading] = useState(false);

    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-pb-5'>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>
            {/* left side menu bar */}

            <SideNavBar />

            {/* right side - main content */}
            <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-pt-5'>


            </main>
        </main >
    )
}

export default QuizList
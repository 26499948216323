import { useState, useEffect } from "react";

const D = () => {


    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds

    useEffect(() => {
        if (timeLeft === 0) {
            alert("Time's up!");
            return;
        }
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
            setTimeLeft(timeLeft - 1);

        }, 1000);
        return () => clearInterval(timer);
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };
    
    return (

        <div>
            <h1>Countdown Timer</h1>
            <h2>{formatTime(timeLeft)}</h2>
        </div>

    );
};




export default D
import React, { useEffect, useState, useRef } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import Loader from '../../layout/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import AnalyzeReport from '../analysis/AnalyzeReport';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import AudioConversation from '../candidateList/AudioConversation';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';

const TraineeList = () => {

    const data = {
        columns: [
            {
                label: "S. No",
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "User Name",
                field: "username",
                sort: "asc",
                width: 150,
            },
            {
                label: "Module Type",
                field: "moduleType",
                sort: "asc",
                width: 150,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Role",
                field: "role",
                sort: "asc",
                width: 200,
            },
            // {
            //     label: "Status",
            //     field: "status",
            //     sort: "asc",
            //     width: 100,
            // },
            {
                label: "Report",
                field: "report",
                sort: "asc",
                width: 100,
            },
        ],
        rows: [],
    };


    const [candidateTraineeList, setCandidateTraineeList] = useState(data);
    const [loading, setLoading] = useState(false);
    const [showanalysis, setshowAnalysis] = useState(false);
    const [analyzeReport, setAnalyzeReport] = useState([]);
    const [showAudioChats, setShowAudioChats] = useState(false);
    const [rinterviewDetails, setrInterviewDetails] = useState({});
    const [associateUsers, setAssociateUsers] = useState([])
    const [trainingType, setTrainingType] = useState("")


    const toastId = React.useRef(null);
    const selectedRollNo = parseInt(localStorage.getItem("user_number"))

    const { getAllInterviewByEmailURL, getAudioAnalysisByInterviewIdURL, getUsersFromCompanyNameURL, getAnalysisByKnowledgeInterviewIdURL } = jarsisURLS;


    const filteredAssociates = async (usersList) => {
        return usersList.filter(item => {
            return parseInt(item?.roleNumber) >= selectedRollNo
        })
    }


    const getAiType = (name) => {
        if (name === 'pitch_to_ai') {
            return 'Pitch To AI';
        } else {
            return 'Knowledge To AI';
        }
    }
    // useEffect(() => {
    //     console.log("analyze>>>>>>>>>>>>>>>>>>", analyzeReport)
    // }, [analyzeReport])


    useEffect(() => {

        const fetchData = async (usersList = null) => {
            try {
                setLoading(true);
                let reqObj = { isTraining: true, emailId: `${localStorage.getItem("user_emailId")}` }
                if (usersList && selectedRollNo <= 5) {
                    const associateUsers = await filteredAssociates(usersList);
                    const emailIds = [];
                    // console.log("associateUsers-------->", associateUsers);
                    associateUsers.map(val => {
                        emailIds.push(val?.emailId)
                    })
                    reqObj = { isTraining: true, emailId: emailIds }
                }
                const res = await httpPost(getAllInterviewByEmailURL, reqObj)

                if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {
                    // console.log("res----------->", res);

                    const details = res?.data?.payload?.interviewDetails;
                    // console.log('details)', details);

                    const rows = details.map((val, index) => ({
                        sno: index + 1,
                        name: val?.trainingDetails?.name ? val?.trainingDetails?.name : "",
                        username: val?.candidateName ? val?.candidateName : "",
                        moduleType: val?.trainingDetails?.aiType ? getAiType(val?.trainingDetails?.aiType) : "",
                        role: val?.trainingDetails?.role ? val?.trainingDetails?.role : "",
                        //  status: getStatus(val),
                        report: val.interviewConversations.length !== 0 || val.reports.length !== 0 || val.messages.length !== 0 ? <a onClick={() => viewAnalyzeReport(val?._id, val?.trainingDetails?.aiType ? val?.trainingDetails?.aiType : "")} className='tw-text-blue-400' href='javascript:void(0);'>View Analysis</a> : <p className='tw-text-black'>No Chat History</p>,
                        //report: val?.trainingDetails?.aiType === "knowledge_to_ai" ? <a onClick={() => viewAnalyzeReport(val?._id)} className='tw-text-blue-400' href='javascript:void(0);'>View Analysis</a> : <p className='tw-text-black'>No Chat History</p>,
                    }));

                    setCandidateTraineeList((prevState) => ({
                        ...prevState,
                        rows,
                    }));

                } else {
                    setCandidateTraineeList([]);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setCandidateTraineeList([])
            }

        }
        const getAssociatedUsers = async () => {
            try {
                setLoading(true);
                const res = await httpPost(getUsersFromCompanyNameURL, { companyName: `${localStorage.getItem("user_company")}` })
                //console.log("users associates------->", res?.data)
                const { payload } = res?.data;
                const { usersList } = payload;
                setAssociateUsers(usersList);
                setLoading(false)
                fetchData(usersList);
            } catch (e) {
                setLoading(false)
                fetchData();
            }

        }
        //  fetchData();
        getAssociatedUsers();
    }, [])

    const viewAnalyzeReport = async (interviewId, type) => {

        try {
            setTrainingType(type);
            setLoading(true);
            //toast.info("Please wait, Generating report.....")
            // toastId.current = toast.info("Please wait, Generating report.....");
            // const res = await axios.post('https://api.jarsis.ai/analysis/getAudioAnalysisByInterviewId', { "interviewId": interviewId }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
            // const res = await httpPost(getAudioAnalysisByInterviewIdURL, { "interviewId": interviewId })

            let res = null;
            if (type === 'knowledge_to_ai') {
                res = await httpPost(getAnalysisByKnowledgeInterviewIdURL, { interviewId })
            } else {
                res = await httpPost(getAudioAnalysisByInterviewIdURL, { interviewId })
            }
            //const res = await httpPost(getAnalysisByKnowledgeInterviewIdURL, { interviewId })
            // setTimeout(() => {
            //     toast.dismiss(toastId.current)
            // }, 1000);

            if (res.data.success) {
                setshowAnalysis(true);
                const { payload } = res?.data;
                try {
                    const resultString = payload?.result
                    // console.log("resultString------------>", typeof resultString === 'object')
                    // console.log("resultString11111------------>", typeof resultString.otherAnalysis)
                    if (typeof resultString === 'object') {
                        resultString.otherAnalysis = JSON.parse(resultString.otherAnalysis)
                        //console.log("resultString222222------------>", resultString.otherAnalysis)
                        setAnalyzeReport(resultString); // Store structured data
                        setrInterviewDetails(payload?.interviewDetails);
                    } else {
                        const resultArray = resultString.includes('JSON') ? resultString.split("JSON:") : resultString;
                        const parsedOutPut = JSON.parse(resultString.includes('JSON') ? resultArray[1] : resultArray);
                        setAnalyzeReport(parsedOutPut); // Store structured data
                        setrInterviewDetails(payload?.interviewDetails);
                    }

                } catch (e) {
                    console.log("EEEEE------------>", e)
                }

            } else {
                if (res.status === 350) {
                    toast.error("Sorry,No Chat history")
                } else {
                    toast.error("Sorry,Can't generate report");
                }
                setshowAnalysis(false);
                setAnalyzeReport([]);
                setrInterviewDetails({})
            }
            setLoading(false);
        } catch (e) {
            console.log("EEEERRRRR11111----------->", e);
            toast.error("Sorry,Please try again");
            setLoading(false);
            setAnalyzeReport([])
            setshowAnalysis(false);
        }
    }

    const constructOtherAnalysis = (analyzeReport, prop) => {
        //console.log(">>>>>>>>>>>>>>>>>>>>", analyzeReport?.otherAnalysis?.Discovery_Questions)
        //console.log("111111111>>>>>>>>>>>>>>>>>>>>", prop)
        if (analyzeReport?.otherAnalysis?.["Discovery Questions"] && prop === 'Discovery') {
            return analyzeReport?.otherAnalysis?.["Discovery Questions"].toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.Discovery_Questions && prop === 'Discovery') {
            return analyzeReport?.otherAnalysis?.Discovery_Questions.toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.["Discovery Questions"] && prop === 'DiscoveryFeedback') {
            return analyzeReport?.otherAnalysis?.Feedback?.["Discovery Questions"];
        }
        if (analyzeReport?.otherAnalysis?.Discovery_Questions && prop === 'DiscoveryFeedback') {
            return analyzeReport?.otherAnalysis?.Feedback?.Discovery_Questions;
        }
        if (analyzeReport?.otherAnalysis?.["Social Proof"] && prop === 'Social') {
            return analyzeReport?.otherAnalysis?.["Social Proof"].toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.Social_Proof && prop === 'Social') {
            return analyzeReport?.otherAnalysis?.Social_Proof.toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.["Social Proof"] && prop === 'SocialFeedback') {
            return analyzeReport?.otherAnalysis?.Feedback?.["Social Proof"].toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.Social_Proof && prop === 'SocialFeedback') {
            return analyzeReport?.otherAnalysis?.Feedback?.Social_Proof;
        }
        if (analyzeReport?.otherAnalysis?.["Next Best Action"] && prop === 'Next') {
            return analyzeReport?.otherAnalysis?.["Next Best Action"].toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.Next_Best_Action && prop === 'Next') {
            return analyzeReport?.otherAnalysis?.Next_Best_Action.toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.["Next Best Action"] && prop === 'NextFeedback') {
            return analyzeReport?.otherAnalysis?.Feedback?.["Next Best Action"].toLowerCase();
        }
        if (analyzeReport?.otherAnalysis?.Next_Best_Action && prop === 'NextFeedback') {
            return analyzeReport?.otherAnalysis?.Feedback?.Next_Best_Action;
        }
        return prop;
    }

    //console.log("analyzeReport----------->", analyzeReport)
    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>
            <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="lg:tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">Trainee List</p>
                        {/* <button className='tw-text-white tw-bg-black tw-rounded-lg tw-py-2 tw-px-4'>Schedule a Train/ing</button> */}
                    </div>
                    <MDBDataTable
                        data={candidateTraineeList}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        responsive
                        striped
                        small
                        className="tw-rounded-lg tw-z-0"
                    />
                </section>

                <Modal show={false} onHide={() => setshowAnalysis(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Analyze Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <AnalyzeReport analyzeReport={analyzeReport} />
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Audio:-
                            </h1>
                            {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                                {/* <audio src={rinterviewDetails?.interviewAudio} controls /> */}
                                <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                            </div>}
                            {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                Processing......
                            </p>}
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setshowAnalysis(false)}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
                    </Modal.Footer>
                </Modal>

                {showAudioChats && <AudioConversation showAudioChats={showAudioChats} setShowAudioChats={setShowAudioChats} newaudioData={rinterviewDetails?.interviewAudioSeparate} candidateName={rinterviewDetails?.candidateName} />}

                <SlidingPane
                    className="tw-w-[90%] md:tw-w-[50%] xl:tw-w-[40%] tw-z"
                    overlayClassName="some-custom-overlay-class"
                    isOpen={showanalysis}
                    title="Analysis Report"
                    subtitle=""
                    onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click
                        setshowAnalysis(!showanalysis)
                    }}
                >


                    {trainingType === 'pitch_to_ai' && <div class="tw-grid lg:tw-grid-cols-4 tw-gap-1">
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Talk/Listen Ratio</strong></p>
                            <p>{analyzeReport?.talk_listen_ratio?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.talk_listen_ratio?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.talk_listen_ratio?.status}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Filler Words</strong></p>
                            <p>{analyzeReport?.filler_words?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.filler_words?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.filler_words?.status}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Talk Speed</strong></p>
                            <p>{analyzeReport?.talkSpeed?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.talkSpeed?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.talkSpeed?.status}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Monologue</strong></p>
                            <p>{analyzeReport?.longestMonologue?.value}</p>
                            <p> <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${analyzeReport?.longestMonologue?.status !== 'good' ? "tw-bg-red-600" : "tw-bg-green-600"}`} ></span> {analyzeReport?.longestMonologue?.status}</p>
                        </div>


                    </div>}

                    {trainingType === 'pitch_to_ai' && <div class="tw-grid lg:tw-grid-cols-2 tw-gap-1 tw-mt-5">
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Opener</strong>&nbsp;
                                {analyzeReport?.otherAnalysis?.Opener.toLowerCase() === 'yes' ? <span className="text-green-500">✅</span> : <span className="text-red-500">❌</span>}</p>
                            <p>{analyzeReport?.otherAnalysis?.Feedback?.Opener}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Discovery</strong>&nbsp;
                                {constructOtherAnalysis(analyzeReport, 'Discovery') === 'yes' ? <span className="text-green-500">✅</span> : <span className="text-red-500">❌</span>}</p>
                            <p>{constructOtherAnalysis(analyzeReport, 'DiscoveryFeedback')}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Social Proof</strong>&nbsp;
                                {constructOtherAnalysis(analyzeReport, 'Social') === 'yes' ? <span className="text-green-500">✅</span> : <span className="text-red-500">❌</span>}</p>
                            <p>{constructOtherAnalysis(analyzeReport, 'SocialFeedback')}</p>
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Best Action</strong>&nbsp;
                                {constructOtherAnalysis(analyzeReport, 'Next') === 'yes' ? <span className="text-green-500">✅</span> : <span className="text-red-500">❌</span>}</p>
                            <p>{constructOtherAnalysis(analyzeReport, 'NextFeedback')}</p>
                        </div>
                    </div>}





                    {trainingType === 'knowledge_to_ai' && <div >
                        {analyzeReport.referenceDocument && <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Reference Docuemnt</strong></p>
                            <p>{analyzeReport.referenceDocument}</p>
                        </div>}

                    </div>}
                    {trainingType === 'knowledge_to_ai' && <div class="tw-grid lg:tw-grid-cols-2 tw-gap-1 tw-mt-5">
                        {/* <pre>{JSON.stringify(analyzeReport)}</pre> */}
                        {analyzeReport?.ProductDetails && <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Product Name</strong></p>
                            <p>{analyzeReport?.ProductDetails}</p>
                            <p><strong className='tw-text-[#9b5feb]'>Overall Score</strong></p>
                            <p>{analyzeReport?.OverallScore}</p>
                        </div>}
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Incorrect Information</strong></p>
                            {analyzeReport.IncorrectInformation && analyzeReport?.IncorrectInformation.map(val => {
                                return val?.details.map((item) => {
                                    return <p> <span className="text-red-500">{item}</span></p>
                                })

                            })}
                            {/* <p><strong className='tw-text-[#9b5feb]'>Compositions</strong></p>
                            {analyzeReport.IncorrectInformation && analyzeReport?.IncorrectInformation.map(val => {
                                return val?.compositions?.length === 0 ? <p> <span className="text-red-500">Null</span></p> : <p> <span className="text-red-500">{val?.compositions.toString()}</span></p>

                            })} */}
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Matched Information</strong></p>
                            {analyzeReport.MatchedInformation && analyzeReport?.MatchedInformation.map(val => {
                                return val?.details.map((item) => {
                                    return <p> <span className="text-red-500">{item}</span></p>
                                })

                            })}
                            <p><strong className='tw-text-[#9b5feb]'>Compositions</strong></p>
                            {analyzeReport.MatchedInformation && analyzeReport?.MatchedInformation.map(val => {
                                return val?.compositions?.length === 0 ? <p> <span className="text-red-500">Null</span></p> : <p> <span className="text-red-500">{val?.compositions.join(', ')}</span></p>

                            })}
                        </div>
                        <div className='tw-border-2 tw-border-solid tw-border-gray-400 tw-justify-center tw-p-3'>
                            <p><strong className='tw-text-[#9b5feb]'>Missed Information</strong></p>
                            {analyzeReport.MissedInformation && analyzeReport?.MissedInformation.map(val => {
                                return val?.details.map((item) => {
                                    return <p> <span className="text-red-500">{item}</span></p>
                                })

                            })}

                        </div>
                    </div>}
                    <div>
                        <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400"> Audio:- </h1>
                        {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                            {/* <audio src={rinterviewDetails?.interviewAudio} controls /> */}
                            <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                        </div>}
                        {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                            Processing......
                        </p>}
                    </div>
                </SlidingPane>
            </main >
        </>
    )
}

export default TraineeList
const jarsisURLS = {
    // user
    loginURL: '/user/loginUser',
    registrationURL: '/user/createUser',
    getUserDetailsURL: '/user/getUserDetails',
    sendOtpToEmailURL: '/user/sendOtpToEmail',
    updateUserURL: '/user/updateUser',
    updateUserPasswordURL: '/user/updateUserPassword',
    verifyOtpURL: '/user/verifyOtp',
    getAllUsersURL: '/user/getAllUsers',
    getUsersFromCompanyNameURL: '/user/getUsersFromCompanyName',
    checkIfUserExistsURL: '/user/checkIfUserExists',

    // invitation
    sendInviteMessageToEmailURL: '/invitation/sendInviteMessageToEmail',
    addUserToInvitationListURL: '/invitation/addUserToInvitationList',
    getAllUsersFromInvitationListURL: '/invitation/getAllUsersFromInvitationList',
    changeInvitedUserActiveStatusURL: '/invitation/changeInvitedUserActiveStatus',

    // invitation roles
    addRoleInInvitationRolesURL: '/invitationRole/addRoleInInvitationRoles',
    getAllRolesFromInvitationRolesURL: '/invitationRole/getAllRolesFromInvitationRoles',



    // interviewQuestion
    createInterviewQuestionsURL: '/interviewQuestion/createInterviewQuestion',
    getAllInterviewQuestionsURL: '/interviewQuestion/getAllInterviewQuestions',
    updateInterviewQuestionURL: '/interviewQuestion/updateInterviewQuestions',
    deleteInterviewQuestionsURL: '/interviewQuestion/deleteInterviewQuestions',

    // interview
    getAllInterviewsURL: '/interview/getAllInterviews',
    createInterviewURL: '/interview/createInterview',
    getAllInterviewByEmailURL: '/interview/getAllInterviewsByEmail',
    getAllInterviewsByTrainingIdURL: '/interview/getAllInterviewsByTrainingId',
    getAllInterviewsForAdminURL: '/interview/getAllInterviewsForAdmin',
    getInterviewDetailsURL: '/interview/getInterviewDetails',
    updateInterviewDetailsURL: '/interview/updateInterviewDetails',
    stratInterviewUrl: '/interview/startInterview',


    // training
    getTrainingDetailsURL: '/training/getTrainingDetails',
    updateTrainingURL: '/training/updateTraining',
    createTrainingURL: '/training/createTraining',
    getAllTrainingsURL: '/training/getAllTrainings',

    // fileUpload
    getAWSS3PresignedUrlURL: '/fileUpload/getAWSS3PresignedUrl',

    // division
    getAllDivisionsURL: '/division/getAllDivisions',
    createDivisionURL: '/division/createDivision',

    // language
    getAllLanguagesURL: 'language/getAllLanguages',

    // character
    getAllCharactersURL: '/character/getAllCharacters',
    createCharacterURL: '/character/createCharacter',

    // analysis
    getAnalysisbyInterviewIdURL: '/analysis/getAnalysisByInterviewId',
    getAudioAnalysisByInterviewIdURL: '/analysis/getAudioAnalysisByInterviewId',
    getAnalysisByKnowledgeInterviewIdURL: '/analysis/getAnalysisByKnowledgeInterviewId',

    // drugs
    getAllDrugURL: '/drug/getAllDrug',
    createDrugURL: '/drug/createDrug',

    //resume
    createResumeURL: '/resume/createResume',
    getAllResumeURL: '/resume/getAllResume',

    // Quiz
    getAllQuizURL: '/quizRoutes/getAllQuiz',
    sentQuizInviteURL: '/quizRoutes/sentQuizInvite',
    saveQuizProductURL: '/quizRoutes/saveQuizProduct',
    getAllQuestionsURL: '/quizRoutes/getAllQuestions',
    getQuesProductDetailsURL: '/quizRoutes/getQuesProductDetails',
    updateQuizDetailsURL: '/quizRoutes/updateQuizDetails',
    updateQuizProductDetailsURL: '/quizRoutes/updateQuizProductDetails'

}
export default jarsisURLS;
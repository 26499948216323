import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader';
import SideNavBar from '../../layout/SideNavBar';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';

const MyProgress = () => {
    const [loading, setLoading] = useState(false);
    const { getQuesProductDetailsURL } = jarsisURLS;

    const [totalQuiz, setTotalQuiz] = useState(null);
    const [completedQuiz, setCompletedQuiz] = useState(null);
    const [totalQuizAttempts, setTotalQuizAttempts] = useState(0);
    const [avgScore, setAvgScore] = useState(0);
    const [avgTime, setAvgTime] = useState(0);

    useEffect(() => {

        const calculateAverageTime = (times) => {
            // Convert time strings ("MM:SS") to total seconds
            const totalSeconds = times.map(time => {
                const [minutes, seconds] = time.split(":").map(Number);
                return minutes * 60 + seconds;
            });

            // Calculate average time in seconds
            const avgSeconds = totalSeconds.reduce((a, b) => a + b, 0) / totalSeconds.length;

            // Convert back to MM:SS format
            const avgMinutes = Math.floor(avgSeconds / 60);
            const avgRemSeconds = Math.floor(avgSeconds % 60);

            // console.log("avg time-------------------->", `${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}`)
            
            if (`${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}` === 'NaN:NaN') {
                setAvgTime(0)
            } else {
                setAvgTime(`${avgMinutes.toString().padStart(2, "0")}:${avgRemSeconds.toString().padStart(2, "0")}`)

            }
        };

        const getUserDetails = async () => {
            try {
                setLoading(true);
                const res = await httpPost(getQuesProductDetailsURL, { emailId: localStorage.getItem("user_emailId") })
                if (res?.data?.success) {
                    const { payload } = res?.data;
                    const { allQuestions } = payload;
                    console.log("allQuestions------------->", allQuestions)
                    setTotalQuiz(allQuestions) // get all quiz


                    let completedQuizs = allQuestions?.filter((val) => { // get completed quiz
                        return val.isCompleted === true
                    })
                    setCompletedQuiz(completedQuizs.reverse())
                    // console.log("quizAttemptCount---------->", quizAttemptCount?.length);
                    let quizAttemptCount = completedQuizs?.length
                    setTotalQuizAttempts(completedQuizs?.length) // get completed quiz count

                    let totalScore = 0
                    completedQuizs.map((val) => {
                        totalScore = totalScore + Number(val.score)
                    })
                    if (totalScore === 0 || quizAttemptCount === 0) {
                        setAvgScore(0)
                    } else {

                        setAvgScore(Math.round(totalScore / quizAttemptCount))
                    }
                    console.log("totalScore------------->", totalScore);

                    let totalTime = [] // get all times as array
                    completedQuizs.map(val => {
                        totalTime.push(val.completion_time)
                    })
                    // console.log("totalTime---------->", totalTime);

                    calculateAverageTime(totalTime) // calling avg time find functions



                    ///


                    setLoading(false);
                } else {
                    setLoading(false);
                }

            } catch (e) {
                setLoading(false)
            }
        }
        getUserDetails()



    }, [])

    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-pb-5'>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>
            {/* left side menu bar */}

            <SideNavBar />

            {/* right side - main content */}
            <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-py-5 bg-red tw-bg-gradient-to-br tw-from-indigo-50 tw-to-purple-50 md:tw-bg-none'>

                <div className="md:tw-p-8 tw-bg-gradient-to-br tw-from-indigo-50 tw-to-purple-50 tw-rounded-lg">
                    <h1 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-indigo-800 tw-bg-clip-text tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600">
                        My Learning Progress
                    </h1>


                    <main className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-6 tw-mt-5">

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-amber-50 tw-to-amber-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className=" tw-font-medium tw-text-amber-700 tw-text-lg tw-mb-3">Quiz Attempts</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-amber-800">{totalQuizAttempts}</p>
                            <p className="tw-text-sm tw-text-amber-600 tw-mt-2">Overall quiz attempts</p>
                        </div>

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-purple-50 tw-to-purple-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className=" tw-font-medium tw-text-purple-700 tw-text-lg tw-mb-3">Avg. Score</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-purple-800">{avgScore}%</p>
                            <input type='range' value={avgScore} className="tw-mt-2 tw-bg-purple-200 tw-w-full" />
                        </div>

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-red-50 tw-to-red-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className=" tw-font-medium tw-text-red-700 tw-text-lg tw-mb-3">Total Quiz</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-red-800">{totalQuiz?.length}</p>
                            <p className="tw-text-sm tw-text-red-600 tw-mt-2">Keep building your Knowledge!</p>
                        </div>

                        <div className="tw-p-5 tw-overflow-hidden tw-border-0 tw-shadow-lg tw-bg-gradient-to-br tw-from-green-50 tw-to-green-100 tw-rounded-xl hover:tw-shadow-xl tw-transition-all tw-duration-300">
                            <p className=" tw-font-medium tw-text-green-700 tw-text-lg tw-mb-3">Avg. Time</p>

                            <p className="tw-text-3xl tw-font-bold tw-text-green-800">{avgTime}s</p>
                            <p className="tw-text-sm tw-text-green-600 tw-mt-2">Average quiz completion time</p>
                        </div>

                    </main>

                    <p className="tw-text-xl md:tw-text-2xl tw-font-bold tw-text-indigo-800 tw-bg-clip-text tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600 tw-mt-20 tw-mb-5  ">Recent Quiz Attempts</p>
                    <main className='md:tw-bg-white md:tw-p-5 tw-rounded-3xl'>

                        {completedQuiz && completedQuiz.length === 0 ?
                            <p className='tw-text-xl tw-text-indigo-700  tw-bg-gradient-to-br  tw-p-10 tw-from-indigo-50 tw-to-purple-50 tw-text-center tw-rounded-md'>No Quiz Attempts Found</p>
                            :

                            completedQuiz?.map((val) => (
                                <section key={val._id} className=" tw-overflow-hidden tw-border-0 tw-bg-gradient-to-br tw-my-5 tw-from-indigo-50 tw-to-purple-50 tw-shadow-lg hover:tw-shadow-xl tw-transition-all tw-duration-300  tw-rounded-xl tw-cursor-pointer" >
                                    <div className=' tw-pl-4 tw-pr-3 tw-py-4'>
                                        <p className="tw-text-xl tw-text-indigo-700 ">{val.quizId.quizName}</p>
                                        <p className="tw-text-indigo-600 tw-mt-4">{val.quizId.quizDescription}</p>
                                    </div>
                                    <div className="tw-bg-indigo-200 tw-flex tw-px-7 tw-py-3  tw-gap-x-5">
                                        <p className="tw-bg-indigo-100 tw-text-indigo-700 tw-px-3 tw-py-2 tw-rounded-xl tw-text-sm tw-border tw-border-indigo-200">
                                            Score: {val.score}%
                                        </p>
                                        <p className="tw-bg-indigo-100 tw-text-indigo-700 tw-px-3 tw-py-2 tw-rounded-xl tw-text-sm tw-border tw-border-indigo-200">
                                            Time: {val.completion_time}s
                                        </p>

                                    </div>
                                </section>
                            ))}


                    </main>
                </div>
            </main>
        </main >
    )
}

export default MyProgress
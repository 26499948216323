import React, { useEffect } from 'react'
import history from '../../img/new/history.svg'
import video from '../../img/new/video.svg'
import call from '../../img/new/call.svg'
import dr1 from '../../img/new/doctor/dr-1.png'
import { useState } from 'react'
import Loader from '../../layout/Loader'
import axios from 'axios'
import { toast } from 'react-toastify'
import jarsisURLS from '../../apiUtils/AxiosURLS'
import { httpPost } from '../../apiUtils/AxiosConfig'

const BeforeCallContent = ({ doctorAIMain }) => {
    const doctorAIData = [doctorAIMain];

    const { stratInterviewUrl, createInterviewURL } = jarsisURLS;
    //console.log("doctorAIData----------->", doctorAIData)

    const [showCall, setShowCall] = useState(false);
    const [loading, setLoading] = useState(false);

    const connectCall = async (details, pwd) => {
        //  href={`https://meet.jarsis.ai/${item?._id}?pwd=${item?.password}`}
        // var in_uri = `https://meet.jarsis.ai/${id}?pwd=${pwd}`;
        // var theTop = ((screen.height / 2) - (theHeight / 2)) / 2;
        // var theLeft = (screen.width / 2) - (theWidth / 2);
        // var features = 'height=600,width=800,top=' + theTop + ',left=' + theLeft + ',toolbar=1,Location=0,Directories=0,Status=0,menubar=1,Scrollbars=1,Resizable=1';

        // window.open(in_uri, WindowName, features);



        setLoading(true);

        //const res = await axios.post(stratInterviewUrl, { interviewId: details?._id }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
        const res = await httpPost(stratInterviewUrl, { interviewId: details?._id });
        //console.log("res1111--------------------->", res)
        let tempToken = res?.data?.payload?.tempToken;
        let interviewid = details?._id;
        setLoading(false);
        if (res?.data?.message && res?.data?.message === "Interview Already Started") {

            try {
                const reqObj = {
                    candidateEmail: details?.candidateEmail,
                    candidateName: details?.candidateName,
                    trainingDetails: details?.trainingDetails?._id,
                    isTraining: true,
                    levels: details?.trainingDetails?.levels
                }
                setLoading(true);
                // const res = await axios.post('/interview/createInterview', reqObj, {
                //     headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } 
                // })

                const res = await httpPost(createInterviewURL, reqObj)
                //console.log("resres=========>", res)
                if (res?.data?.success) {
                    const { _id } = res?.data?.payload?.createdInterview;
                    interviewid = _id;
                    // const response = await axios.post('/interview/startInterview', { interviewId: _id }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } });
                    const response = await httpPost(stratInterviewUrl, { interviewId: _id });
                    tempToken = response?.data?.payload?.tempToken;
                    //toast.success("Training assigned to users");
                } else {
                    // console.log("ERR=========>" + index)
                }
                setLoading(false);
            } catch (e) {
                console.log("ERR=========>", e)
                setLoading(false);
            }

        }

        var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
        var URL = `https://meet.jarsis.ai/${interviewid}?t=${tempToken}`;
        var win = window.open(URL, "_self", strWindowFeatures);
    }

    return (
        <>

            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>

            {doctorAIMain ? <main className="md:tw-w-[75%] tw-border tw-rounded-xl tw-p-5">
                <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                    <p className="tw-font-medium tw-text-gray-700">Jarsis AI</p>

                    {/* <div className='tw-flex tw-gap-x-3'>
                        <img src={history} alt="" className='tw-border tw-p-2 tw-rounded-lg tw-cursor-pointer' />
                        <button className='tw-bg-black tw-text-white tw-flex tw-items-center tw-mx-auto tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>Edit </button>
                    </div> */}
                </div>
                {doctorAIData?.map((item, index) => (
                    <main key={index} className='tw-bg-[#f6f6fa] tw-p-5 tw-rounded-lg '>


                        <div className='tw-flex tw-gap-x-2 tw-mt-2 tw-bg-white tw-w-fit tw-p-2 tw-rounded-lg'>
                            {item?.trainingDetails?.character.map((val, index) => {
                                return <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                    <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${index % 2 === 0 ? "tw-bg-red-600" : "tw-bg-green-600"}`}></span>
                                    <span className='tw-text-xs'>{val.title}</span>
                                </p>
                            })}


                            {/* <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                                <span className='tw-text-xs'>Brainy</span>
                            </p>

                            <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
                                <span className='tw-text-xs'>Professional</span>
                            </p> */}

                        </div>
                        <div>
                            <img src={item?.trainingDetails?.aiImage} alt="" className='tw-object-cover tw-mx-auto tw-mt-32 tw-h-40 tw-w-40 tw-rounded-full' />

                            <p className='tw-font-medium tw-text-3xl tw-mt-5 tw-text-center'>{item?.trainingDetails?.name}</p>
                            <p className='tw-text-sm tw-text-[#6d6d6d] tw-text-center'>{item?.trainingDetails?.role}</p>


                            <main className='tw-flex tw-w-fit tw-mx-auto tw-gap-x-1 tw-bg-white tw-mt-20 tw-p-2 tw-rounded-full tw-mb-10'>
                                <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#74ff6c] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                                    <img src={call} alt="Description of the image" className='tw-w-4' />
                                    <a onClick={() => connectCall(item?.names[item?.names.length - 1], item?.password)} href="javascript:void(0);" className='tw-font-medium'>Start Call</a>
                                </div>

                                {/* <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#80c6ff] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                                <img src={video} alt="Description of the img" className='tw-w-4' />
                                <p className='tw-font-medium'>Start Video Call</p>
                            </div> */}

                                {/* <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#e43e29] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                <img src="/img/call-slash.svg" alt="Description of the img"  className='tw-w-4'/>
                <p className='tw-font-medium tw-text-white'>End Call</p>
            </div> */}
                            </main>
                        </div>
                    </main>
                ))}
            </main> : <main className="tw-w-[75%] tw-border tw-rounded-xl tw-p-5">No data found</main>}
        </>
    )
}

export default BeforeCallContent
import React, { useState, useEffect } from 'react'
import Loader from '../../layout/Loader'
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import TopTitleBar from '../../layout/TopTitleBar';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';
import Select from 'react-select';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Signup = () => {

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { registrationURL, changeInvitedUserActiveStatusURL, getAllRolesFromInvitationRolesURL } = jarsisURLS;
  const paramValue = new URLSearchParams(window.location.search)
  const emailValueFromQueryString = paramValue.get('email')
  const designationValueFromQueryString = paramValue.get('designation')
  const companyValueFromQueryString = paramValue.get('companyName')
  // // companyName
  // console.log(emailValueFromQueryString);
  // console.log(designationValueFromQueryString);

  const [loading, setLoading] = useState(false);


  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();

  const [roleOptions, setRoleOptions] = useState([]); //show roles in the dropdown
  const [roleDropdownId, setRoleDropdownIdId] = useState('') //get role _id from dropdown
  const [roleDropdownStringValue, setRoleDropdownStringValue] = useState('') //get role string value from dropdown
  const [role, setRole] = useState('') //get string value from text input 
  const [showDropdownErr, setShowDropdownErr] = useState(false)
  const [roleNumberState, setRoleNumberState] = useState('')


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await httpPost(getAllRolesFromInvitationRolesURL, {});
        // console.log(res.data.payload)
        const responseData = res.data.payload
        const roleList = responseData.map((val) => ({
          value: val?._id,
          label: val?.role,
          ...val
        }))
        // console.log("roleList---------->", roleList);
        setRoleOptions(roleList)
        if (companyValueFromQueryString) {
          setValue("company", companyValueFromQueryString)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }

    }
    fetchData()
  }, [])

  useEffect(() => {
    if (role || roleDropdownId) {
      setShowDropdownErr(false)
    }
  }, [role, roleDropdownId])
  const onSubmitHandle = async (e) => {

    let name = e.name
    let emailId = e.email
    let companyName = e.company
    if (companyValueFromQueryString) {
      companyName = companyValueFromQueryString
    }

    let roleNumber = roleNumberState

    if (designationValueFromQueryString) {

      const selectedRole = roleOptions.filter(val => val.role === designationValueFromQueryString)
      roleNumber = selectedRole[0]?.roleNumber
    }

    let jobTitle = e.job?.role ? e.job?.role : "Medical Rep"
    let phoneNumber = e.phone
    let password = e.password

    if (emailValueFromQueryString) {
      emailId = emailValueFromQueryString
    }
    if (designationValueFromQueryString) {
      jobTitle = designationValueFromQueryString
    }
    if (!role && !roleDropdownId) {
      setShowDropdownErr(true)
    }



    setLoading(true)

    try {
      const reqObj = { emailId, password, companyName, phoneNumber, jobTitle, name, roleNumber };
      const res = await httpPost(registrationURL, reqObj);
      if (res.data.success === true) {
        localStorage.setItem("usertoken", res.data.payload.token)
        localStorage.setItem("user_name", res?.data?.payload?.userDetails?.name)
        localStorage.setItem("user_emailId", res?.data?.payload?.userDetails?.emailId)
        localStorage.setItem("user_number", res?.data?.payload?.userDetails?.roleNumber)
        try {
          await httpPost(changeInvitedUserActiveStatusURL, { emailId });
          toast.success("User Created"); // Only show after both calls succeed
        } catch (e) {
          toast.error(e.response?.data?.message || "Failed to change active");
        }


        navigate('/landing')
      }
      setLoading(false)

    } catch (error) {
      console.log(error);
      setLoading(false)
      toast.error(error.response.data.message)

    }
  }


  const addDropdownvalues = (e) => {
    console.log("EEE--------->", e)
    setError("job", "")
    setRoleDropdownIdId(e?.value)
    setRoleDropdownStringValue(e?.label)
    setValue("job", e)
    setRoleNumberState(e.roleNumber)
  }


  return (
    <>

      <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
        <Loader />
      </div>

      {/* navbar */}
      <TopTitleBar />


      <main className='tw-mx-auto tw-w-[80%] lg:tw-w-[40%] tw-my-12 lg:tw-my-20'>
        <div className="text-center tw-mb-10">
          <h3 className="text-body-highlight tw-text-3xl tw-font-semibold">Sign Up</h3>
          <p className="text-body-tertiary">Create your account today</p>
        </div>

        <Form onSubmit={handleSubmit(onSubmitHandle)}>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="name">Name</Form.Label>
            <Form.Control
              id="name"
              type="text"
              placeholder="Name"
              autoComplete='off'
              {...register('name', {
                required: '*Name is required',
                pattern: {
                  value: /^[a-zA-Z. ]+$/, // Only letters (a-z, A-Z) 
                  message: "Only letters allowed",
                },
              })} />

            {errors.name && <p className='tw-text-red-500 tw-mt-2'>{errors.name.message}</p>}
          </Form.Group>

          {emailValueFromQueryString ?
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                id="email"
                type="email"
                readOnly
                value={emailValueFromQueryString}
              />


            </Form.Group>
            :
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                id="email"
                type="email"
                placeholder="name@example.com"
                autoComplete='off'
                {...register('email', {
                  required: '*Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
                })} />

              {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}

            </Form.Group>

          }
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="company">Company Name</Form.Label>
            <Form.Control id="company" type="text" placeholder="Company Name" autoComplete='off' {...register('company', {
              required: '*Company Name is required',
              disabled: companyValueFromQueryString ? true : false
            })} />

            {errors.company && <p className='tw-text-red-500 tw-mt-2'>{errors.company.message}</p>}
          </Form.Group>

          {designationValueFromQueryString ?
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="job">Designation</Form.Label>
              <Form.Control id="job" type="text" placeholder="Designation" autoComplete='off' readOnly value={designationValueFromQueryString} />
            </Form.Group>
            :
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="job">Designation</Form.Label>
              {/* <Form.Control id="job" type="text" placeholder="Designation" autoComplete='off'  {...register('job', {
                required: '*Designation is required'
              })} /> */}
              <Select
                options={roleOptions}
                {...register('job', {
                  required: '*Designation is required'
                })}
                onChange={addDropdownvalues}
                isSearchable
                isClearable
                isDisabled={role}
                placeholder="Select Role..."
              />
              {errors.job && <p className='tw-text-red-500 tw-mt-2'>{errors.job.message}</p>}
            </Form.Group>
          }

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="phone">Mobile Number</Form.Label>
            <Form.Control id="phone" type="number" className='no-spinner' autoComplete='off' placeholder="Mobile Number without +" {...register('phone', {
              required: '*Mobile Number is required',
              minLength: {
                value: 10,
                message: '*Mobile Number must be at least 10 Number',
              },
              maxLength: {
                value: 10,
                message: '*Mobile Number cannot exceed more than 10 Number',
              },
            })} />

            {errors.phone && <p className='tw-text-red-500 tw-mt-2'>{errors.phone.message}</p>}
          </Form.Group>



          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="password">Password</Form.Label>
            <div className='tw-relative'>
              <Form.Control className="mb-2" id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                {...register('password', {
                  required: '*Password is required',
                  minLength: {
                    value: 4,
                    message: '*Password must be at least 4 characters',
                  },
                  maxLength: {
                    value: 14,
                    message: '*Password cannot exceed more than 14 characters',
                  },
                })}
              />
              <p onClick={() => setShowPassword(!showPassword)} className='tw-absolute tw-top-2 tw-right-5 tw-cursor-pointer'>{showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}</p>
            </div >

            {errors.password && <p className='tw-text-red-500 tw-mt-2'>{errors.password.message}</p>}
          </Form.Group >

          <Button disabled={loading} variant="primary" type='submit' className="w-100 mb-3 tw-mt-10" >
            Sign up
          </Button>

          <div className="text-center">
            <Link
              to={`/`}
              className="fs-9 fw-bold"
            >
              Sign in to an existing account
            </Link>
          </div>
        </Form >
      </main >
    </>
  )
}

export default Signup
import React, { useEffect, useRef, useState } from 'react';
import pdfToText from 'react-pdftotext';
import * as Parser from "js-resume-parser";
import { FileUploader } from 'react-drag-drop-files';
import axios from 'axios';
import Loader from '../../layout/Loader';
import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import { Button, Modal } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import PdfViewer from './PdfViewer';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import { httpPost } from '../../apiUtils/AxiosConfig';

const ResumeUpload = () => {
    const { getAllResumeURL, createResumeURL, getAWSS3PresignedUrlURL } = jarsisURLS;
    const fileTypes = ["PDF", "DOCX"];
    const [loading, setLoading] = useState(false);
    const fileRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState(null)
    const [selectedPdf, setSelectedPdf] = useState("");
    const [showPdf, setShowPdf] = useState(false);
    const [isupdated, setIsupdated] = useState(false);
    const tabledata = {
        columns: [
            {
                label: <div className='tw-text-center'>S. No</div>,
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 200,
            },
            {
                label: "Phone",
                field: "phoneNumber",
                sort: "asc",
                width: 200,
            },
            {
                label: "ResumeURL",
                field: "url",
                sort: "asc",
                width: 100,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 100,
            }

        ], rows: []
    };

    const [resumeData, setResumeData] = useState(tabledata);

    useEffect(() => {
        console.log("resumeData------------->", resumeData)
    }, [uploadedFiles, resumeData, isupdated, showPdf])


    const handleShowPdf = (url) => {
        setSelectedPdf(url);
        setShowPdf(true);
    }

    const getResumeList = async () => {
        try {
            setLoading(true);
            //const res = await axios.post('https://api.jarsis.ai/resume/getAllResume', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
            const res = await httpPost(getAllResumeURL, {})
            if (res.data.success && res?.data?.payload?.resumesList.length !== 0) {
                const details = res?.data?.payload?.resumesList;

                const rows = details.map((val, index) => ({
                    sno: <div className='tw-text-center'>{index + 1}</div>,
                    name: val?.name ? val?.name : "",
                    email: val?.emailId ? val?.emailId : "",
                    phoneNumber: val?.phoneNumber ? val?.phoneNumber : "",
                    url: val?.s3BucketUrl ? val?.s3BucketUrl : "",
                    action: <button type="button" onClick={() => handleShowPdf(val?.s3BucketUrl)} class="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 mb-2 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 focus:tw-outline-none dark:focus:tw-ring-blue-800">View</button>

                }));

                setResumeData((prevState) => ({
                    ...prevState,
                    rows,
                }));

            } else {
                setResumeData(tabledata);
            }
            setLoading(false);
            setIsupdated(!isupdated)
        } catch (e) {
            setLoading(false);
            setResumeData(tabledata);
        }
    }

    useEffect(() => {

        getResumeList();
    }, [])

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const extractText = async (file, isshow = false) => {
        //const file = event.target.files[0];
        try {
            console.log("file------>", file)
            let data = null;
            let fileName = file.name;
            if (file.type === "application/pdf") {
                data = await Parser.getDataFromPDF(file);
                fileName = makeid(10) + ".pdf";
            } else {
                data = await Parser.getDataFromDocx(file);
                fileName = makeid(10) + ".docx";
            }
            console.log("data----------->", data)
            console.log("fileName----------->", fileName)
            const { email, phone_number } = data;




            // const res = await axios.post('https://api.jarsis.ai/fileUpload/getAWSS3PresignedUrl', { filePath: fileName }, {
            //     headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
            // })
            const res = await httpPost(getAWSS3PresignedUrlURL, { filePath: fileName })

            if (res?.data?.success) {
                const { payload } = res?.data;
                const { fileUrl, presignedUrl } = payload;

                const response = await fetch(presignedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                    body: file,
                });

                if (response.ok) {
                    try {
                        // const res = await axios.post('https://api.jarsis.ai/resume/createResume', { name: "", emailId: data?.email, phoneNumber: data?.phone_number, s3BucketUrl: fileUrl }, {
                        //     headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                        // });
                        const res = await httpPost(createResumeURL, { name: "", emailId: data?.email, phoneNumber: data?.phone_number, s3BucketUrl: fileUrl })
                        if (res?.data?.success) {
                            if (isshow) {
                                toast.success("Resume saved successfully");
                                await getResumeList()
                                setLoading(false);
                                fileRef.current.value = "";
                                setUploadedFiles(null);
                                setIsupdated(val => !val);

                            }
                        } else {
                            toast.error("Resume already exist please check");
                            setLoading(false);

                        }
                    } catch (e) {
                        console.log("EEEE---------->", e)
                        if (e?.response?.data?.message && e?.response?.data?.message === 'emailId already exists') {
                            toast.error("Resume already exist please check");
                        }

                        setLoading(false);
                    }
                    // imgRef.current.value = fileName
                    // setLoading(false)
                    // setProfileImage(fileUrl);
                } else {
                    // setLoading(false)
                    // let value = URL.createObjectURL(file);

                    // setProfileImage(value);
                }


            }

            console.log("res data-------------->", res?.data)

        } catch (e) {
            setLoading(false)
            console.log('Upload Error------>', e)
        }

        // pdfToText(file)
        // Parser.getDataFromPDF(file)
        //     .then(text => {
        //         console.log(text)
        //         let doc = nlp(text.text)
        //         let str = doc.people().normalize().text()
        //         let email = doc.emails().normalize().text()
        //         let phone = doc.phoneNumbers().normalize().text()
        //         console.log("Str----------->", str)
        //         console.log("email----------->", email)
        //         console.log("phone----------->", phone)
        //     })
        //     .catch(error => console.error("Failed to extract text from pdf"))
    }

    const handleChange = async (files) => {
        console.log("files----------->", files.length)
        setUploadedFiles(files)
        setIsupdated(!isupdated)
        const count = files.length;
        try {
            setLoading(true);
            for (let index = 0; index < files.length; index++) {
                const element = files[index];
                let isShow = false;
                if (index === files.length - 1) {
                    isShow = true;
                }
                await extractText(element, isShow);

            }
        } catch (e) {
            setLoading(false)
        }
    }
    return (
        // <div className="App">
        //     <header className="App-header">
        //         <input type="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={extractText} />
        //     </header>
        // </div> 
        <main className='tw-mx-auto tw-w-full lg:tw-w-[80%] tw-my-12 lg:tw-my-20'>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>
            <div className="text-center tw-mb-10" id="drag_drop">
                <h3 className="text-body-highlight tw-text-3xl tw-font-semibold">Candidate Resume</h3>
                {/* <input type="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={extractText} /> */}
                <FileUploader
                    classes="custom-fileUploader"
                    ref={fileRef}
                    fileOrFiles={uploadedFiles}
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                />
            </div>
            <div>
                {resumeData.length !== 0 && <MDBDataTable
                    data={resumeData}
                    searching={true}
                    pagination={true}
                    noBottomColumns
                    striped
                    responsive
                    small
                    className="tw-rounded-lg"
                />}
            </div>
            <Modal show={showPdf} onHide={() => setShowPdf(!showPdf)} centered >

                {/* <embed src={selectedPdf} width="800px" height="2100px" /> */}
                {/* <Modal.Header closeButton>
                    <Modal.Title>Analyze Report</Modal.Title>
                </Modal.Header> */}
                {/* <Modal.Body> */}
                {/* <div style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height: '750px',
                    }}>
                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
                            <Viewer fileUrl={selectedPdf} />
                        </Worker>
                    </div> */}
                <PdfViewer pdfUrl={selectedPdf} />
                {/* </Modal.Body> */}
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPdf(!showPdf)}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </main>
    );
}

export default ResumeUpload
import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../layout/Loader';
import SideNavBar from '../../layout/SideNavBar';
import { httpPost } from '../../apiUtils/AxiosConfig';
import jarsisURLS from '../../apiUtils/AxiosURLS';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const TakeQuiz = () => {
    const [loading, setLoading] = useState(false);
    const [inviteUsersList, setInviteUsersList] = useState([]);
    const [showTest, setShowTest] = useState(false);
    const [showFinish, setShowFinish] = useState(false);
    const [allQuestions, setAllQuestions] = useState([]);
    const [quizStarted, setQuizStarted] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [selectedOption, setSelectedOption] = useState('');
    const [answers, setAnswers] = useState({});
    const [allAnswers, setAllAnswers] = useState([]);
    const [showQuizModal, setShowQuizModal] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState('');
    const [quizInviteId, setQuizInviteId] = useState('');
    const [questionCount, setQuestionCount] = useState(0);
    const [score, setScore] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [correctAnswers, setCorrectAnswers] = useState(0);

    const [selectedQUiz, setSelectedQuiz] = useState({});


    // timer countown
    const startTime = 600; // for calculating overtall time taken

    const [timeLeft, setTimeLeft] = useState(null); // setting time fot test
    const [isPaused, setIsPaused] = useState(false); // pause the timer
    const [timeTaken, setTimeTaken] = useState(0);  // finding time taken for test


    useEffect(() => {

        // if time ends quiz will completetd 
        if (timeLeft === 0) {
            endQuiz()
        }


        if (isPaused || timeLeft === 0) return;

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
            setTimeLeft(timeLeft - 1);

        }, 1000);
        return () => clearInterval(timer);
    }, [timeLeft, isPaused]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };


    const handleCloseQuizModal = () => setShowQuizModal(false);
    const handleShowQuizModal = () => setShowQuizModal(true);

    const { getAllQuestionsURL, getAllQuizURL, sentQuizInviteURL, getQuesProductDetailsURL, updateQuizDetailsURL } = jarsisURLS;

    const getInviteUsers = async () => {
        try {
            setLoading(true);
            const res = await httpPost(getQuesProductDetailsURL, { emailId: localStorage.getItem("user_emailId") })
            if (res?.data?.success) {
                const { payload } = res?.data;
                const { allQuestions } = payload;
                console.log("allQuestions------------->", allQuestions)

                setInviteUsersList(allQuestions.filter((val) => val.isCompleted === false));
                setLoading(false);
            } else {
                setLoading(false);
            }

        } catch (e) {
            setLoading(false)
        }
    }
    useEffect(() => {
        getInviteUsers()
    }, [])

    const getQuestions = async (quizId) => {
        try {
            setLoading(true)
            const res = await httpPost(getAllQuestionsURL, { quizId: quizId });
            // console.log("getQuestions------->", res);
            if (res?.data?.success) {
                const { payload } = res?.data;
                const { allQuestions } = payload;
                // console.log("getQuestions------->", allQuestions);
                if (allQuestions?.length) {
                    setQuestionCount(allQuestions.length)
                }
                setAllQuestions(allQuestions)

            }
        } catch (e) {
            setLoading(false)
        }
        finally {
            setLoading(false)
        }
    }

    const startTest = (quizId, quizInivteId, index) => {
        console.log("quizInivteId-------------->", inviteUsersList[index]);
        setSelectedQuiz(inviteUsersList[index])
        setQuizInviteId(quizInivteId)
        getQuestions(quizId)
        setShowQuizModal(true)
    }

    const startQuiz = () => {
        setShowTest(true)
        setTimeLeft(600)

        setQuizStarted(!quizStarted)
        console.log("check------------->", allQuestions);
        console.log("check11111------------->", allQuestions[selectedIndex])
        setSelectedQuestion(allQuestions[selectedIndex])
    }

    const nextQuestion = () => {
        if (!selectedOption) {
            setShowErrMsg('*Please Select Anyone of These')
            return
        }

        setSelectedOption('')
        const currentIndex = selectedIndex + 1
        setSelectedIndex(currentIndex);
        setSelectedQuestion(allQuestions[currentIndex])
        setShowErrMsg('')
        setAllAnswers((prev) => {
            const val = [...prev, answers]
            console.log("answers------->", answers);
            console.log("allanswers------->", val);
            return val
        })

    }

    const handleAnswer = (value, index) => {
        setSelectedOption(value)
        setAnswers({ value: value, index: index })
        // console.log('handleAnswer', answers);
        // setAllAnswers(answers)
    }

    const finishQuiz = () => {
        if (!selectedOption) {
            setShowErrMsg('*Please Select Anyone of These')
            return
        }
        setIsPaused(true)
        const timeTakenInSec = startTime - timeLeft;
        const timeTakenInMin = formatTime(timeTakenInSec);
        setTimeTaken(timeTakenInMin)

        setShowFinish(true)
        setSelectedOption('')
        setShowErrMsg('')

        // Count correct answers
        setAllAnswers((prev) => {
            const val = [...prev, answers];
            console.log("answers------->", answers);
            console.log("allanswers------->", val);

            const results = val.map((ans) => {
                const question = allQuestions[ans.index];
                const isCorrect = question && question.answer === ans.value;
                return { ...ans, isCorrect }; // Add correctness info
            });

            console.log("Results with correctness check:", results);

            const correctCount = results.filter(ans => ans.isCorrect).length;
            console.log("Correct Answers Count:", correctCount);
            setCorrectAnswers(correctCount)
            const scorePercentage = questionCount > 0 ? ((correctCount / questionCount) * 100).toFixed(0) : 0;
            const accuracyPercentage = questionCount > 0 ? ((correctCount / questionCount) * 100).toFixed(1) : 0;
            setScore(scorePercentage)
            setAccuracy(accuracyPercentage)
            return val;
        });

    }

    // In middle of test exit quiz
    const endQuiz = () => {

        setShowFinish(true)
        console.log("allAnswers---------->", allAnswers)

        setIsPaused(true)
        const timeTakenInSec = startTime - timeLeft;
        const timeTakenInMin = formatTime(timeTakenInSec);
        setTimeTaken(timeTakenInMin)


        setAllAnswers((val) => {
            // const val = [...prev, answers];
            // console.log("answers------->", answers);
            // console.log("val------->", val);

            const results = val.map((ans) => {
                const question = allQuestions[ans.index];
                const isCorrect = question && question.answer === ans.value;
                return { ...ans, isCorrect }; // Add correctness info
            });

            console.log("Results with correctness check:", results);

            const correctCount = results.filter(ans => ans.isCorrect).length;
            console.log("Correct Answers Count:", correctCount);
            setCorrectAnswers(correctCount)
            const scorePercentage = questionCount > 0 ? ((correctCount / questionCount) * 100).toFixed(0) : 0;
            const accuracyPercentage = questionCount > 0 ? ((correctCount / questionCount) * 100).toFixed(1) : 0;
            setScore(scorePercentage)
            setAccuracy(accuracyPercentage)
            return val;
        });
    }

    // after finishing quiz exit button
    const exitQuiz = async () => {

        try {
            setLoading(true);
            const res = await httpPost(updateQuizDetailsURL, { quizId: quizInviteId, dataToUpdate: { score, accuracy, isCompleted: true, completion_time: timeTaken } })
            if (res?.data?.success) {
                const { payload } = res?.data;

                console.log("updateQuizDetailsURL------------->", payload)

                toast.success('Quiz Completed')
                setLoading(false);
            } else {
                setLoading(false);
            }

        } catch (e) {
            setLoading(false)
        }

        setShowQuizModal(false)
        window.location.reload();
    }


    return (
        <main className='tw-bg-[#fdfdff] lg:tw-flex tw-gap-x-10 poppins lg:tw-mt-6 lg:tw-mx-6 tw-items-start tw-pb-5'>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>
            {/* left side menu bar */}

            <SideNavBar />

            {/* right side - main content */}
            <main className='lg:tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-py-5'>

                <div className="tw-p-8 tw-bg-gradient-to-br tw-from-indigo-50 tw-to-purple-50 tw-rounded-lg">
                    <h1 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-indigo-800 tw-bg-clip-text tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600">
                        Available Learning Materials
                    </h1>

                    <section className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 tw-mt-10">
                        {inviteUsersList.map((allQuestion, index) => {

                            const { quizId } = allQuestion;
                            return <div key={"invite" + index} className=" tw-overflow-hidden tw-border-0 tw-shadow-lg hover:tw-shadow-xl tw-transition-all tw-duration-300  tw-rounded-xl tw-cursor-pointer" >
                                <div className="tw-h-2 tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600"></div>
                                <div className=' tw-pl-4 tw-pr-3 tw-py-4'>
                                    <p className="md:tw-text-xl tw-text-indigo-700 ">{quizId.quizName}</p>
                                    <p className="tw-text-indigo-600 tw-mt-4 tw-text-sm">{quizId.quizDescription || 'Take this quiz to test your knowledge.'}</p>
                                </div>
                                <div className="tw-bg-indigo-200 tw-flex tw-px-4 md:tw-px-7 tw-py-3 tw-justify-between tw-items-center">
                                    <p className="tw-bg-indigo-100 tw-text-indigo-700 tw-px-3  tw-py-2 tw-rounded-xl tw-text-sm md:tw-text-base tw-border tw-border-indigo-200">
                                        Quiz
                                    </p>
                                    <button onClick={() => startTest(quizId?._id, allQuestion._id, index)} className="tw-text-sm md:tw-text-base tw-bg-indigo-600 hover:tw-bg-indigo-700 tw-text-white tw-px-3 md:tw-px-5 tw-py-2 tw-rounded-xl">
                                        Start Learning
                                    </button>

                                </div>
                            </div>
                        })}


                    </section>
                    {inviteUsersList.length === 0 &&
                        <p className='tw-bg-white tw-rounded-xl tw-text-indigo-600 tw-w-full tw-text-center tw-py-20 md:tw-py-40 tw-text-2xl md:tw-text-3xl'>No Learning Materials Available</p>
                    }
                    <Modal keyboard={false} backdrop={'static'} size='lg' centered show={showQuizModal} onHide={handleCloseQuizModal} className='  md:tw-min-h-[700px] '>

                        <Modal.Body className=' md:tw-min-h-[400px]'>

                            {/* {
                                allQuestions.length === 0 &&
                                < div className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                                    <Loader />
                                </div>
                            } */}

                            < div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                                <Loader />
                            </div>
                            {/* before test */}
                            {!showTest && !showFinish &&
                                < main >
                                    <p className='tw-text-indigo-700 tw-font-semibold tw-text-lg md:tw-text-2xl lg:tw-text-3xl'>{selectedQUiz?.quizId?.quizName}</p>
                                    <p className='tw-text-indigo-600 tw-py-1 lg:tw-py-3'>

                                        {selectedQUiz?.quizId?.quizDescription || 'Take this quiz to test your knowledge.'}
                                    </p>
                                    <div className='tw-bg-[#eef2ff] tw-p-2 md:tw-p-4 tw-rounded-xl'>
                                        <p className='tw-text-indigo-700 tw-font-medium md:tw-text-lg'>Quiz Details</p>
                                        <p className='tw-text-indigo-600 tw-text-sm md:tw-text-base'>{questionCount === 0 ? "Loading Questions..." : (questionCount + " questions")} • Approximately 10:00 Minutes to complete</p>

                                    </div>

                                    <div className='tw-bg-[#f0fdf4] tw-p-2 md:tw-p-4 tw-rounded-xl tw-mt-5'>
                                        <p className='tw-text-green-700 tw-font-medium md:tw-text-lg'>Achievement</p>
                                        <p className='tw-text-green-600 tw-text-sm md:tw-text-base'>70% or higher to pass • Earn streak badges for consistent scores</p>
                                    </div>

                                    <div className='tw-bg-[#fffbeb] tw-p-2 md:tw-p-4 tw-rounded-xl tw-mt-5'>
                                        <p className='tw-text-orange-700 tw-font-medium md:tw-text-lg'>Time Limit</p>
                                        <p className='tw-text-orange-600 tw-text-sm md:tw-text-base'>The quiz will automatically submit if the timer runs out</p>
                                    </div>
                                    <div className='tw-flex tw-justify-between tw-mt-5 lg:tw-mt-10'>
                                        <Button variant="primary" onClick={handleCloseQuizModal} disabled={questionCount === 0}>
                                            Exit Quiz
                                        </Button>
                                        <Button variant="primary" onClick={() => startQuiz()} disabled={questionCount === 0}>
                                            Start Quiz
                                        </Button>
                                    </div>
                                </main>
                            }

                            {/* test */}
                            {showTest && !showFinish &&
                                <main>
                                    <div className=''>
                                        <div className=" tw-text-indigo-700 tw-flex tw-justify-between tw-items-center">
                                            <p className=' md:tw-text-lg'>Question {selectedIndex + 1} of {questionCount === 0 ? "Loading Questions..." : questionCount} </p>
                                            <h2 className='tw-text-lg md:tw-text-xl tw-mr-5'>{formatTime(timeLeft)}</h2>
                                        </div>
                                        {quizStarted && <div>
                                            <p className="  lg:tw-text-xl tw-text-indigo-800 tw-font-semibold">{selectedQuestion?.question}</p>
                                            <p className='tw-text-red-500 tw-font-medium'>{showErrMsg}</p>
                                            {selectedQuestion?.options.map((option, index) => (
                                                <p key={index}
                                                    className={`tw-bg-[#e0e7ff] tw-p-3 md:tw-p-4 tw-my-3 tw-rounded-xl tw-cursor-pointer tw-duration-500 ${selectedOption === option ? "tw-bg-indigo-500 tw-duration-500 tw-text-white" : ""
                                                        }`} onClick={() => handleAnswer(option, selectedIndex)}>{option}</p>
                                            ))}

                                            <div className='tw-flex tw-justify-between'>
                                                {<Button variant="primary" onClick={() => endQuiz()} >End Quiz</Button>}
                                                {selectedIndex === questionCount - 1 ? <Button variant="primary" onClick={() => finishQuiz()} >Finish Quiz</Button> : <Button variant="primary" onClick={() => nextQuestion()}>Next Question</Button>}
                                            </div>

                                        </div>}
                                    </div>
                                </main>}

                            {/* finish Test */}
                            {showFinish &&
                                <main className='md:tw-mx-8 lg:tw-mx-14'>
                                    <p className='tw-mt-5 tw-text-indigo-700 tw-font-semibold tw-text-center tw-text-2xl md:tw-text-3xl'>Quiz completed!</p>
                                    <p className='tw-text-indigo-600 tw-text-center tw-mt-1 md:tw-text-lg'>Keep practicing, you can do better next time!</p>
                                    <div className='tw-flex tw-justify-center tw-mt-10 tw-w-full tw-gap-x-10'>
                                        <div className='tw-bg-[#eef2ff] tw-flex tw-flex-col tw-items-center tw-w-[50%] tw-p-5 tw-rounded-xl'>
                                            <p className='tw-text-indigo-600 tw-text-lg'>Score</p>
                                            <p className='tw-text-indigo-700 tw-font-semibold tw-text-3xl'>{score}%</p>
                                        </div>
                                        <div className='tw-bg-[#eef2ff] tw-flex tw-flex-col tw-items-center tw-w-[50%] tw-p-5 tw-rounded-xl'>
                                            <p className='tw-text-indigo-600 tw-text-lg'>Time</p>
                                            <p className='tw-text-indigo-700 tw-font-semibold tw-text-3xl'>{timeTaken}</p>
                                        </div>
                                    </div>
                                    <div className='tw-text-indigo-600 tw-flex tw-justify-between  tw-mt-5'>
                                        <p  >Correct Answers</p>
                                        <p className='tw-bg-[#c4f7d6] tw-text-green-700 tw-px-3 tw-rounded-md'>{correctAnswers}/{questionCount}</p>
                                    </div>
                                    <div>

                                        <div className='tw-text-indigo-600 tw-flex tw-justify-between  tw-mt-5'>
                                            <p  >Accuracy</p>
                                            <p>{accuracy}</p>
                                        </div>
                                        <input className='tw-w-full ' type="range" value={score} />

                                    </div>
                                    <div className='tw-flex tw-justify-center'>
                                        <Button className='tw-w-40 tw-mt-5 ' variant="primary" onClick={exitQuiz} disabled={loading}>
                                            Exit Quiz
                                        </Button>
                                    </div>
                                </main>
                            }
                        </Modal.Body>

                    </Modal>
                </div>
            </main>

        </main >
    )
}

export default TakeQuiz